import React, { useCallback, useEffect, useState, useRef } from "react";
import { CssBaseline, Stack, TextField, Autocomplete, Box } from "@mui/material";
import { Button } from "primereact/button";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { fetchCategoryEvents } from "../../Apiservices/Auth/servers/fetchCategoryEvents";
import * as Yup from "yup";
import { Calendar } from "primereact/calendar";
import config from "../../config";
import Footer from "../Footer";
import ResponsiveAppBar from "../appBarcomponent/ResponsiveAppbar";
import { createEvent } from "../../Apiservices/Auth/servers/createEvents";
import { useSelector } from "react-redux";
import { GoogleMap, useLoadScript, MarkerF } from "@react-google-maps/api";

const EventFormDashboardAdd = () => {
  const navigate = useNavigate();
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const [error, setError] = useState(null);
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [eventData, setEventData] = useState([]);

  const eventLocalStorageKey = config.eventLocalStorageKey;
  const today = new Date();
  const oneYearFromToday = new Date(
    new Date().setFullYear(today.getFullYear() + 1)
  );

  const cancelEvent = () => {
    // setIsFirstVisit(false);
    navigate("/dashboard");
  }

  const googleMapsApiKey = process.env.REACT_APP_GOOGLE_API_KEY;

  const [address, setAddress] = useState({});
  const [formattedAddress, setFormattedAddress] = useState(null);
  const [center, setCenter] = useState({ lat: 0, lng: 0 });
  const [markerPosition, setMarkerPosition] = useState({ lat: 0, lng: 0 });

  const isValidLatLng = (lat, lng) =>
    typeof lat === "number" &&
    !isNaN(lat) &&
    typeof lng === "number" &&
    !isNaN(lng);
  // Get current location using Geolocation API
  const getCurrentLocation = () => {
    console.log("Get current Location is called");
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          console.log(position.coords);
          console.log("Latitude , lognttude");
          if (isValidLatLng(latitude, longitude)) {
            const newPosition = { lat: latitude, lng: longitude };
            setCenter(newPosition);
            setMarkerPosition(newPosition);

            const geocoder = new window.google.maps.Geocoder();
            geocoder.geocode({ location: newPosition }, (results, status) => {
              console.log("Geocoder", results);
              if (status === "OK" && results[0]) {
                console.log("Results", results[0]);
                setAddress(getAddressObject(results[0].address_components));
                setFormattedAddress(results[0].formatted_address);
              } else {
                console.error("Geocoder failed due to: " + status);
              }
            });
          }
        },
        (error) => {
          console.error("Error getting location:", error);
        }
      );
    } else {
      console.error("Geolocation not supported by this browser.");
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = "";
        const [events_data] = await Promise.all([fetchCategoryEvents(token)]);
        setEventData(events_data);
        // setFilteredOptions(events_data.map((e) => e.name));
      } catch (error) {
        setError("Failed to fetch data.");
      }
    };
    fetchData();
    console.log("Use effect is called")
    if (window.google)
      getCurrentLocation();
  }, []);

 

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      Date: "",
      endDate: "",
      startTime: "",
      endTime: "",
      locationLat: markerPosition.lat ? markerPosition.lat : '',
      locationLong: markerPosition.lng ? markerPosition.lng : '',
      area: address.locality ? address.locality : '',
      city: address.city ? address.city : '',
      state: address.state ? address.state : '',
      eventTitle: "",
      eventDesc: "",
      Address: formattedAddress ? formattedAddress : "",
      event: { event: "", id: "" },
    },
    validationSchema: Yup.object({
      event: Yup.object({
        event: Yup.string().required("Event is required"),
      }),
      Date: Yup.string().required("Event start date is required"),
      endDate: Yup.string().required("Event end date is required"),
      Address: Yup.string().required("Address is required"),
    }),
    onSubmit: async (values) => {
      try {
        // Create Event Body here
        // postEventafterLogin();
        const eventBody = {
          eventTitle: values.eventTitle,
          event: values.event,
          eventDesc: values.eventDesc,
          Date: values.Date, // Make sure this is in the correct format
          Address: values.Address,
          endDate: values.endDate,
          startTime: values.startTime,
          endTime: values.endTime,
          locationLat: values.locationLat,
          locationLong: values.locationLong,
          area: values.area,
          city: values.city,
          state: values.state,
        };
        try {
          await createEvent(eventBody);
        } catch (error) {
          console.log("Something Went Wrong while Creating Event ");
          console.log(error);
        } finally {
          // setIsFirstVisit(false);
          navigate("/dashboard");
        }
        console.log(values);
      } catch (ex) {
        setError(ex.toString());
      }
    },
  });


  const handleFieldChange = (field) => (event) => {
    console.log("Handle Field Change is called")
    console.log(field, event.target.value)
    const value = event.target.value;
    formik.setFieldValue(field, value);
  };

  const textFieldStyles = {
    marginBottom: "8px",
    paddingTop: "2px",
    paddingBottom: "2px",
    backgroundColor: "transparent",
    width: "100%",
    zIndex: 1500,
    position: "relative",
  };

  const mapContainerStyle = {
    height: "100%",
    width: "100%",
  };

  const handleEventChange = (field, value) => {
    console.log("Handle Event Change is called")
    console.log(field , value)
    const selectedEvent = eventData.find((e) => e.name === value) || {
      id: "",
      name: value,
    };

    // Update Formik
    formik.setFieldValue(field, {
      event: selectedEvent.name,
      id: selectedEvent.id,
    });

  };

  const getAddressObject = (address_components) => {
    var ShouldBeComponent = {
      streetNumber: ["premise", "street_number"],
      pincode: ["postal_code"],
      locality: ["neighborhood", "locality", "sublocality"],
      street: ["route", "sublocality_level_1", "sublocality_level_2"],
      state: [
        "administrative_area_level_1",
        "administrative_area_level_2",
        "administrative_area_level_4",
        "administrative_area_level_5",
      ],
      city: [
        "locality",
        "sublocality_level_1",
        "sublocality_level_3",
        "sublocality_level_4",
        "administrative_area_level_3",
      ],
      country: ["country"],
    };

    const address = {
      streetNumber: "",
      pincode: "",
      locality: "",
      street: "",
      state: "",
      city: "",
      country: "",
    };

    address_components.forEach((component) => {
      for (var shouldBe in ShouldBeComponent) {
        if (ShouldBeComponent[shouldBe].indexOf(component.types[0]) !== -1) {
          address[shouldBe] = component.long_name;
        }
      }
    });
    return address;
  };

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: googleMapsApiKey, // Replace with your actual API key
  });

  const handleMarkerDragEnd = (event) => {
    console.log("Handle Marker Drag End is called")
    if (event && event.latLng) {
      const newPosition = {
        lat: parseFloat(event.latLng.lat()),
        lng: parseFloat(event.latLng.lng()),
      };
      setMarkerPosition(newPosition);
      const geocoder = new window.google.maps.Geocoder();
      geocoder.geocode({ location: newPosition }, (results, status) => {
        if (status === "OK" && results[0]) {
          console.log("Results", results[0]);
          setAddress(getAddressObject(results[0].address_components));
          setFormattedAddress(results[0].formatted_address);
          console.log("Set Address is called")
        } else {
          console.error("Geocoder failed due to: " + status);
        }
      });
    }
  };

  const handleEventInputChange = (event, value) => {
    // Update Formik field value
    console.log("Handle Event Input change is called")
    handleEventChange("event", value);
    if (value.length >= 3) {
      const newFilteredOptions = eventData
        .map((e) => e.name) // Ensure you're mapping to the right property
        .filter((option) => option.toLowerCase().includes(value.toLowerCase()));
      setFilteredOptions(newFilteredOptions); // Update the filtered options
    } else {
      setFilteredOptions([]);
    }
  };
  const renderAutocomplete = (label, options, field) => (
    <Autocomplete
      options={filteredOptions}
      getOptionLabel={(option) => option}
      freeSolo
      // disablePortal
      value={formik.values[field].event}
      onInputChange={handleEventInputChange}
      onChange={(event, value) => {
        console.log("On Change is called")
        handleEventChange(field, value);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          variant="outlined"
          size="small"
          error={formik.touched.event?.event && Boolean(formik.errors.event?.event)}
          helperText={"Please enter 3 or more letters of the event"}
          sx={textFieldStyles}
          onBlur={() => formik.setFieldTouched(field, true)} // Set touched for the field
        />
      )}
      renderOption={(props, option, { selected }) => (
        <li
          {...props}
          key={option + Math.random()}
          style={{ listStyle: "none" }}
        >
          {option}
        </li>
      )}
    /* PopperProps={{
      style: { zIndex: 1500 }, // This makes the dropdown render above other components
    }} */
    />
  );

  const calendarRef = useRef(null)

  const closeCalender = () => {
    calendarRef.current.hide()
  };

  return (
    <div style={{ width: "100%" }}>
      <CssBaseline />
      <Stack spacing={7} sx={{ flexGrow: 1 }}>
        <ResponsiveAppBar params={"dashboard"} />
      </Stack>
      <div className="tab-container">
        <div className="frontend-container">
          <div style={{ position: 'relative', width: "100%" }}>
            <form onSubmit={formik.handleSubmit} className="event-form-inner">
              <div className="event-form-head">
                <h2>Create New Event</h2>
                <div className="btns">
                  <Button
                    icon="pi pi-angle-left"
                    className="custom-button"
                    label="Go Back"
                    onClick={cancelEvent}
                  />
                </div>
              </div>
              <div className="event-form-body list">
                <Box sx={{ position: 'relative' }}>
                  {isLoaded ? (
                    <GoogleMap
                      className="map-container"
                      mapContainerStyle={mapContainerStyle}
                      center={center}
                      zoom={18}
                    >
                      <MarkerF
                        position={markerPosition}
                        draggable={true}
                        onDragEnd={handleMarkerDragEnd}
                      />
                    </GoogleMap>
                  ) : (
                    <div>Loading...</div>
                  )}
                </Box>
                <div>
                  {renderAutocomplete(
                    "Select Event",
                    eventData.map((e) => e.name),
                    "event"
                  )}

                  <TextField
                    type="hidden"
                    name="locationLat"
                    value={formik.values.locationLat}
                    sx={{ visibility: 'hidden' }}
                  />
                  <TextField
                    type="hidden"
                    name="locationLong"
                    value={formik.values.locationLong}
                    sx={{ visibility: 'hidden' }}
                  />

                  <TextField
                    name="eventDesc"
                    label="Whose event is it?"
                    value={formik.values.eventDesc}
                    onChange={handleFieldChange("eventDesc")} // Use common change handler
                    size="small"
                    variant="outlined"
                    sx={textFieldStyles}
                  />

                  <div style={{ marginBottom: "10px" }}>
                    <Calendar
                      baseZIndex={1100}
                      view="date"
                      minDate={today}
                      maxDate={oneYearFromToday}
                      //showButtonBar
                      showIcon
                      dateFormat="dd-M-yy"
                      value={formik.values.Date}
                      onChange={(e) => {
                        formik.setFieldValue("Date", e.value);
                        // updateLocalStorage({
                        //   ...formik.values,
                        //   Date: e.value && e.value.toString(),
                        // });
                      }}
                      placeholder="Event Start Date"
                      style={{ width: "100%", background: "transparent", border: "none" }}
                      inputStyle={{
                        fontWeight: '400',
                        backgroundColor: "transparent",
                        width: "100%",
                        border:
                          formik.touched.Date && formik.errors.Date
                            ? "1px solid red"
                            : "1px solid rgba(0, 0, 0, 0.23)",
                      }}
                      panelStyle={{ zIndex: 1100 }}
                      showTime hourFormat="12"
                      ref={calendarRef}
                      footerTemplate={(e) => <div className="p-datepicker-buttonbar"><button aria-label="OK" className="p-button-secondary p-button-text p-button p-component" type="button" onClick={closeCalender}><span className="p-button-label p-c">OK</span></button></div>}
                    />
                    {formik.touched.Date && formik.errors.Date && (<p className="MuiFormHelperText-root Mui-error MuiFormHelperText-sizeSmall MuiFormHelperText-contained">{formik.errors.Date}</p>)}
                  </div>

                  <div style={{ marginBottom: "10px" }}>
                    <Calendar
                      baseZIndex={1100}
                      view="date"
                      minDate={today}
                      maxDate={oneYearFromToday}
                      //showButtonBar
                      showIcon
                      dateFormat="dd-M-yy"
                      value={formik.values.endDate}
                      onChange={(e) => {
                        formik.setFieldValue("endDate", e.value);
                        // updateLocalStorage({
                        //   ...formik.values,
                        //   endDate: e.value && e.value.toString(),
                        // });
                      }}
                      placeholder="Event End Date"
                      style={{ width: "100%", background: "transparent", border: "none" }}
                      inputStyle={{
                        fontWeight: '400',
                        backgroundColor: "transparent",
                        width: "100%",
                        border:
                          formik.touched.endDate && formik.errors.endDate
                            ? "1px solid red"
                            : "1px solid rgba(0, 0, 0, 0.23)",
                      }}
                      panelStyle={{ zIndex: 1100 }}
                      showTime hourFormat="12"
                      ref={calendarRef}
                      footerTemplate={(e) => <div className="p-datepicker-buttonbar"><button aria-label="OK" className="p-button-secondary p-button-text p-button p-component" type="button" onClick={closeCalender}><span className="p-button-label p-c">OK</span></button></div>}
                    />
                    {formik.touched.endDate && formik.errors.endDate && (<p className="MuiFormHelperText-root Mui-error MuiFormHelperText-sizeSmall MuiFormHelperText-contained">{formik.errors.Date}</p>)}
                  </div>

                  <TextField
                    type="hidden"
                    name="area"
                    value={formik.values.area}
                    sx={{ visibility: 'hidden' }}
                  />
                  <TextField
                    type="hidden"
                    name="city"
                    value={formik.values.city}
                    sx={{ visibility: 'hidden' }}
                  />
                  <TextField
                    type="hidden"
                    name="state"
                    value={formik.values.state}
                    sx={{ visibility: 'hidden' }}
                  />
                  <TextField
                    disabled
                    name="Address"
                    label="Event Location/Venue"
                    value={formik.values.Address}
                    onChange={handleFieldChange("Address")} // Use common change handler
                    size="small"
                    variant="outlined"
                    multiline
                    rows={3}
                    sx={textFieldStyles}
                    error={formik.touched.Address && Boolean(formik.errors.Address)}
                    helperText="Please Use the Map marker Pin to Change address"
                  />
                  {error && <p style={{ color: "red" }}>{error}</p>}
                  <div className="btns group">
                    <Button
                      type="submit"
                      className="custom-button"
                      label="Create Event"
                    />
                    <Button
                      className="custom-button cancel"
                      label="Cancel"
                      onClick={cancelEvent}
                    />
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <Footer />
    </div >
  );
};

export default EventFormDashboardAdd;
