import React from 'react'
import {
    Box,
    Button,
    TextField,
} from "@mui/material";
import SendIcon from '@mui/icons-material/Send';

function NewsLetter() {
    return (

        <div className='frontend-container'>
            <div className='newsletter'>
                <Box
                    sx={{
                        display: "flex", // Flexbox for horizontal layout
                        flexDirection: {xs: 'column', md: 'row'},
                        alignItems: "center",
                        justifyContent: "space-between",
                        gap: {xs: '20px', md: '50px'}, // Space between TextField and Button
                        width: "100%",
                    }}
                >
                    <div style={{
                        textAlign: 'left'
                    }}>
                        <h2 style={{
                            fontWeight: '600',
                            color: '#ffffff'
                        }}>Subscribe to our newsletter</h2>
                        <p style={{
                            fontSize: '14px',
                            color: '#ffffff',
                            opacity: 0.75
                        }}>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse et sapien vel nisi hendrerit fermentum quis ac tortor.</p>
                    </div>
                    <Box
                        sx={{
                            display: "flex", // Flexbox for horizontal layout
                            alignItems: "center",
                            justifyContent: "center", // Center horizontally
                            gap: "10px", // Space between TextField and Button
                            width: "100%",
                            maxWidth: "640px",
                        }}
                    >
                        <TextField
                            label="Enter your email"
                            variant="outlined"
                            fullWidth
                            sx={{
                                flex: 2,
                                "& .MuiOutlinedInput-root": {
                                    color: "#fff",
                                    "& .MuiOutlinedInput-notchedOutline": {
                                        borderColor: "#fff",
                                        borderWidth: "2px",
                                    },
                                    "&.Mui-focused": {
                                        "& .MuiOutlinedInput-notchedOutline": {
                                            borderColor: "#fff",
                                        },
                                    },
                                    "&:hover:not(.Mui-focused)": {
                                        "& .MuiOutlinedInput-notchedOutline": {
                                            borderColor: "#fff",
                                        },
                                    },
                                },
                                "& .MuiInputLabel-outlined": {
                                    color: "#fff",
                                    opacity: 0.75,
                                    "&.Mui-focused": {
                                        color: "#fff",
                                        opacity: 1
                                    },
                                },
                            }} // Take up more space
                        />
                        <Button
                            variant="outlined"
                            color="primary"
                            endIcon={<SendIcon />}
                            sx={{
                                flex: {xs: 'none', md: 1},
                                height: "56px",
                                color: "#ffffff",
                                borderColor: "#ffffff",
                                borderWidth: "2px",
                                fontWeight: '500',
                                minWidth: {xs: 'unset'},
                                width: {xs: 'auto'},
                                ":hover": {
                                    backgroundColor: "#ffffff",
                                    color: "#0cc0df"
                                }
                            }} // Button height to match TextField
                        ><Box component="span" sx={{ display: {xs: 'none', md: 'inline'} }}>Subscribe</Box></Button>
                    </Box>
                </Box>
            </div>
        </div>
    )
}

export default NewsLetter

