import React, { useState, useEffect, useCallback } from "react";
import AccountDialog from "../AccountDialog";
import ServicesDialog from "../ServicesDialog";
import { getProfile } from "../../Apiservices/Auth/servers/profileServer";
import {
  AppBar,
  Toolbar,
  IconButton,
  Menu,
  MenuItem,
  Box,
  InputBase,
} from "@mui/material";
import { Button } from "primereact/button";
import MenuIcon from "@mui/icons-material/Menu";
import SearchIcon from "@mui/icons-material/Search";
import { useNavigate } from "react-router-dom";
import SocialMediaIcons from "./SocialMediaIcons";
import common_colors from "../../styles/common_colors";
import SearchEvent from "../eventComponent/SearchEvent";
import useWindowSize from "../../util/useWindowSize";
import { useDispatch, useSelector } from "react-redux";
import { logOut } from "../../store/authSlice";
import { capitalizeFirstLetter } from "../../util/typeCasting";

const ResponsiveAppBar = ({ params }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [anchorEl, setAnchorEl] = useState(null);
  const [isServicesOpen, setIsServicesOpen] = useState(false);
  const [isAccountOpen, setIsAccountOpen] = useState(false);
  const [userDetails, setUsers] = useState({});
  const { width, height } = useWindowSize();
  const navigate = useNavigate();
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  // const isExpire=isAuthenticated();
  // console.log("isexpre:",isAuthenticated())

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const navigatDashboard = () => {
    navigate("/dashboard");
  };
  const loginpag = () => {
    navigate("/login");
  };
  const serviceproviderpage = () => {
    navigate("/service-provider/login");
  }
  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const toggleAccountDialog = () => {
    setIsAccountOpen(!isAccountOpen);
  };
  const toggleServicesDialog = () => {
    setIsServicesOpen(!isServicesOpen);
  };
  const logoutAccount = () => {
    dispatch(logOut()); // Dispatch the logout action to clear user state
    // navigate("/"); // Redirect the user to the login page after logging out
  };

  const fetchuser = useCallback(async () => {
    setLoading(true);
    try {
      const provider_data = await getProfile();
      setUsers(provider_data);
    } catch (error) {
      console.error("Failed to fetch events:", error);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchuser();
  }, [fetchuser]);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    navigate("/");
  };

  //console.log("userDetails", userDetails)

  return (
    <AppBar
      position="fixed"
      style={{
        backgroundColor: "rgba(255, 255, 255, 0.7)", // Semi-transparent white
        backdropFilter: "blur(10px)", // Blur effect
        boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)", // Light shadow for depth
      }}
      enableColorOnDark
    >
      {isLoggedIn && width < 1000 && (<Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', gap: 1, color: '#fff',backgroundColor:'#0cc0df' }}><MenuItem
        className="menu-item"
        style={{color:'#fff'}}
      >
        <i className="pi pi-user" style={{ fontSize: '0.85rem', marginRight: '5px' }}></i> {capitalizeFirstLetter(userDetails?.firstName)}
      </MenuItem>
        <Button
          onClick={logoutAccount}
          label="Logout"
          icon="pi pi-sign-out"
          className="p-button-text p-button-plain"
          size="small"
          style={{ textTransform: 'uppercase' }}
        /></Box>)}
      <Toolbar style={{ width: '100%', maxWidth: '1170px', display: "flex", justifyContent: "space-between", padding: "10px 15px", margin: '0 auto' }}>
        <img
          src={`${process.env.PUBLIC_URL}/assets/images/thurent.png`}
          alt="Logo"
          style={{ height: "80px", cursor: "pointer" }}
          onClick={scrollToTop}
        />
        {
          !isLoggedIn ?
            width > 1000 ?
              <Box sx={{
                maxWidth: "calc(100vw - 25%)",
                display: "flex",
                //flexDirection: "column",
                justifyContent: "flex-end",
                alignItems: "center",
                flexGrow: 1, // Ensures it takes available space
                gap: '10px'
              }}
              >
                <SocialMediaIcons />
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', gap: 1, }}>
                  <MenuItem
                    href="#about-us"
                    className="menu-item"
                  >
                    About Us
                  </MenuItem>

                  <MenuItem
                    className="menu-item"
                    onMouseEnter={(e) => toggleServicesDialog(true)}
                    onMouseLeave={(e) => toggleServicesDialog(false)}
                  >
                    Services<i className="pi pi-sort-down-fill" style={{ fontSize: '0.85rem', marginLeft: '5px' }}></i>
                    <ServicesDialog
                      isOpen={isServicesOpen}
                      onClose={toggleServicesDialog}
                    />
                  </MenuItem>
                  <MenuItem
                    className="menu-item"
                    href="#contact"
                  >
                    Contact
                  </MenuItem>
                  <MenuItem
                    className="menu-item"
                    onClick={loginpag}
                  >
                    Log-in / Sign-Up
                  </MenuItem>
                  <Button
                    onClick={serviceproviderpage}
                    label="Service Provider"
                    icon="pi pi-user"
                    className="p-button-text p-button-plain"
                    aria-label="Menu"
                    size="medium"
                    style={{ textTransform: 'uppercase' }}
                  />
                </Box>
              </Box>
              :
              <Box sx={{
                display: "flex",
                gap: 1
              }}>
                <SocialMediaIcons />
                <IconButton
                  size="large"
                  edge="end"
                  color="inherit"
                  aria-label="menu"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  style={{ color: "#0cc0df" }}
                  onClick={handleMenuClick}
                >
                  <MenuIcon />
                </IconButton>
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  open={Boolean(anchorEl)}
                  onClose={handleMenuClose}
                >
                  <MenuItem>
                    About Us
                  </MenuItem>
                  <MenuItem>
                    Contact
                  </MenuItem>
                  <MenuItem onClick={loginpag}>
                    Log-in / Sign-Up
                  </MenuItem>
                  <MenuItem onClick={serviceproviderpage}>
                    Service Provider
                  </MenuItem>
                </Menu>
              </Box>
            :
            width > 1000 ?
              <Box sx={{
                maxWidth: "calc(100vw - 25%)",
                display: "flex",
                //flexDirection: "column",
                justifyContent: "flex-end",
                alignItems: "center",
                flexGrow: 1, // Ensures it takes available space
                gap: '10px'
              }}>
                <SocialMediaIcons />
                {/* params === "dashboard" && <SearchEvent /> */}
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', gap: 1, color: '#353535' }}>
                  <MenuItem
                    className="menu-item"
                  >
                    <i className="pi pi-user" style={{ fontSize: '0.85rem', marginRight: '5px' }}></i> {capitalizeFirstLetter(userDetails?.firstName)}
                  </MenuItem>
                  <Button
                    onClick={logoutAccount}
                    label="Logout"
                    icon="pi pi-sign-out"
                    className="p-button-text p-button-plain"
                    size="small"
                    style={{ textTransform: 'uppercase' }}
                  />
                </Box>
                {/* 
                    <AccountDialog
                      logout={logoutAccount}
                      isOpen={isAccountOpen}
                      onClose={toggleAccountDialog}
                      users={userDetails}
                    /> */}
              </Box> :
              <Box sx={{ width: '100%' }}>
                {/* params === "dashboard" && width > 1000 && <SearchEvent /> */}
                <Box sx={{ position: 'relative', display: 'flex', alignItems: 'center', justifyContent: 'flex-end', gap: 1 }}>
                  <SocialMediaIcons />
                  {/* <IconButton
                    size="large"
                    edge="end"
                    color="inherit"
                    aria-label="menu"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    style={{ color: "#0cc0df" }}
                    onClick={(e) => toggleAccountDialog(true)}
                  >
                    <MenuIcon />
                  </IconButton>
                  <AccountDialog
                    logout={logoutAccount}
                    isOpen={isAccountOpen}
                    onClose={toggleAccountDialog}
                    users={userDetails}
                  /> */}
                </Box>
              </Box>
        }
        {/* {(params !== "dashboard" && params !== "spdashboard") && ()} */}
      </Toolbar>
    </AppBar>
  );
};

export default ResponsiveAppBar;
