import config from "../../../config";
export const fetchCategoryEvents =  async (token,query) => {
     query='';
    console.log('event token:',token,query);
    try {
        //const params=new URLSearchParams({"contains":query,"startsWith":query,"endsWith":query,"rowCount":query});
        const url =`${config.API_BASE_URL}/event-categories/events${query?("?contains="+query):''}`
        const response = await fetch(url,
            {method:"GET",
                headers:{
                    'Authorization':`${token}`
                },
            }
        );
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        const data = await response.json();
        return data;
    } catch (error) {
        console.error('There was a problem with the fetch operation:', error);
    }
};
