import React, { useEffect, useState, useRef } from "react";
import { CssBaseline, Stack, TextField, Autocomplete, Box } from "@mui/material";
import { Button } from "primereact/button";
import { useFormik } from "formik";
import { useNavigate, useLocation } from "react-router-dom";
import { fetchCategoryEvents } from "../../Apiservices/Auth/servers/fetchCategoryEvents";
import * as Yup from "yup";
import { Calendar } from "primereact/calendar";
import LocationSearchingTwoToneIcon from "@mui/icons-material/LocationSearchingTwoTone";
import config from "../../config";
import Footer from "../Footer";
import ResponsiveAppBar from "../appBarcomponent/ResponsiveAppbar";
import { updateEvent } from "../../Apiservices/Auth/servers/createEvents";
import { fetchEvent } from "../../Apiservices/Auth/servers/fetchEvents";
import { useSelector } from "react-redux";
import { GoogleMap, useLoadScript, MarkerF } from "@react-google-maps/api";
import { setIsFirstVisit } from "../../services/AuthServices";

const EventFormDashboardEdit = () => {

  const location = useLocation();
  const editEventData = location.state || {}; // Get event details

  console.log("editEventData", editEventData);

  const navigate = useNavigate();
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const [error, setError] = useState(null);
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [eventData, setEventData] = useState([]);

  const eventLocalStorageKey = config.eventEditLocalStorageKey;
  const today = new Date();
  const oneYearFromToday = new Date(
    new Date().setFullYear(today.getFullYear() + 1)
  );

  const cancelEvent = () => {
    // setIsFirstVisit(false);
    navigate("/dashboard");
  }

  const googleMapsApiKey = process.env.REACT_APP_GOOGLE_API_KEY;

  const [address, setAddress] = useState({});
  const [formattedAddress, setFormattedAddress] = useState(null);
  const [center, setCenter] = useState({ lat: 0, lng: 0 });
  const [markerPosition, setMarkerPosition] = useState({ lat: editEventData.locationLat, lng: editEventData.locationLong });

  const isValidLatLng = (lat, lng) =>
    typeof lat === "number" &&
    !isNaN(lat) &&
    typeof lng === "number" &&
    !isNaN(lng);
  // Get current location using Geolocation API
  const getCurrentLocation = () => {
    console.log("Get current Location is called");
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          console.log(position.coords);
          console.log("Latitude , lognttude");
          if (isValidLatLng(latitude, longitude)) {
            const newPosition = { lat: latitude, lng: longitude };
            setCenter(newPosition);
            setMarkerPosition(newPosition);
          }
        },
        (error) => {
          console.error("Error getting location:", error);
        }
      );
    } else {
      console.error("Geolocation not supported by this browser.");
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = "";
        const [events_data] = await Promise.all([fetchCategoryEvents(token)]);
        setEventData(events_data);
        // setFilteredOptions(events_data.map((e) => e.name));
      } catch (error) {
        setError("Failed to fetch data.");
      }
    };
    fetchData();
    //let existingData;
    const existingData = localStorage.getItem(eventLocalStorageKey);
    console.log("existingData", existingData);
    if (existingData) {
      const parsedData = JSON.parse(existingData);
      // Ensure date is set as a Date object
      if (parsedData.Date) {
        parsedData.Date = new Date(parsedData.Date);
      }
      formik.setValues(parsedData); // Set form values with existing data
    }
    getCurrentLocation();
  }, []);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      Date: new Date(editEventData.Date),
      endDate: editEventData.endDate ? new Date(editEventData.endDate) : "",
      locationLat: markerPosition.lat,
      locationLong: markerPosition.lng,
      area: address.locality ? address.locality : editEventData.area,
      city: address.city ? address.city : editEventData.city,
      state: address.state ? address.state : editEventData.state,
      eventDesc: editEventData.desc,
      Address: formattedAddress ? formattedAddress : editEventData.Location ? editEventData.Location : "",
    },
    validationSchema: Yup.object({
      Date: Yup.date().default(() => new Date()).required("Event start date is required"),
      endDate: Yup.date().when("Date", (Date, schema) => Date && schema.min(Date)).required("Event end date is required"),
      Address: Yup.string().required("Address is required"),
    }),
    onSubmit: async (values) => {
      try {
        // Create Event Body here
        // postEventafterLogin();
        const eventBody = {
          Date: values.Date.toString(), // Make sure this is in the correct format
          endDate: values.endDate.toString(),
          locationLat: values.locationLat,
          locationLong: values.locationLong,
          area: values.area,
          city: values.city,
          state: values.state,
          eventDesc: values.eventDesc,
          Address: values.Address,
        };

        console.log("EditEventData", eventBody)
        try {
          await updateEvent(eventBody, editEventData.id);
        } catch (error) {
          console.log("Something Went Wrong while Creating Event ");
          console.log(error);
        } finally {
          // setIsFirstVisit(false);
          localStorage.removeItem(config.eventLocalStorageKey);
          navigate("/dashboard");
        }
        console.log(values);
      } catch (ex) {
        setError(ex.toString());
      }
    },
  });

  // Common method to update local storage
  const updateLocalStorage = (updatedValues) => {
    if (!isLoggedIn) {
      console.log("Is not logged In ", updatedValues);
      localStorage.setItem(eventLocalStorageKey, JSON.stringify(updatedValues));
    } else {
      console.log("Is logged In ");
    }
  };

  const handleFieldChange = (field) => (event) => {
    const value = event.target.value;
    formik.setFieldValue(field, value);
    updateLocalStorage({ ...formik.values, [field]: value });
  };
  //to refresh page after create event
  const handleReload = () => {
    window.location.reload();
  };
  const textFieldStyles = {
    marginBottom: "8px",
    paddingTop: "2px",
    paddingBottom: "2px",
    backgroundColor: "transparent",
    width: "100%",
    zIndex: 1500,
    position: "relative",
  };

  const mapContainerStyle = {
    height: "100%",
    width: "100%",
  };
  const handleEventChange = (field, value) => {
    const selectedEvent = eventData.find((e) => e.name === value) || {
      id: "",
      name: value,
    };

    // Update Formik
    formik.setFieldValue(field, {
      event: selectedEvent.name,
      id: selectedEvent.id,
    });

    // Update local storage
    updateLocalStorage({
      ...formik.values,
      [field]: {
        event: selectedEvent.name,
        id: selectedEvent.id,
      },
    });
  };

  const getAddressObject = (address_components) => {
    var ShouldBeComponent = {
      streetNumber: ["premise", "street_number"],
      pincode: ["postal_code"],
      locality: ["neighborhood", "locality", "sublocality"],
      street: ["route", "sublocality_level_1", "sublocality_level_2"],
      state: [
        "administrative_area_level_1",
        "administrative_area_level_2",
        "administrative_area_level_4",
        "administrative_area_level_5",
      ],
      city: [
        "locality",
        "sublocality_level_1",
        "sublocality_level_3",
        "sublocality_level_4",
        "administrative_area_level_3",
      ],
      country: ["country"],
    };

    const address = {
      streetNumber: "",
      pincode: "",
      locality: "",
      street: "",
      state: "",
      city: "",
      country: "",
    };

    address_components.forEach((component) => {
      for (var shouldBe in ShouldBeComponent) {
        if (ShouldBeComponent[shouldBe].indexOf(component.types[0]) !== -1) {
          address[shouldBe] = component.long_name;
        }
      }
    });
    return address;
  };

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: googleMapsApiKey, // Replace with your actual API key
  });

  const handleMarkerDragEnd = (event) => {
    if (event && event.latLng) {
      const newPosition = {
        lat: parseFloat(event.latLng.lat()),
        lng: parseFloat(event.latLng.lng()),
      };
      setMarkerPosition(newPosition);
      const geocoder = new window.google.maps.Geocoder();
      geocoder.geocode({ location: newPosition }, (results, status) => {
        if (status === "OK" && results[0]) {
          console.log("Results", results[0]);
          setAddress(getAddressObject(results[0].address_components));
          setFormattedAddress(results[0].formatted_address);
        } else {
          console.error("Geocoder failed due to: " + status);
        }
      });
    }
  };

  const calendarRef = useRef(null)

  const closeCalender = () => {
    calendarRef.current.hide()
  };

  return (
    <div style={{ width: "100%" }}>
      <CssBaseline />
      <Stack spacing={7} sx={{ flexGrow: 1 }}>
        <ResponsiveAppBar params={"dashboard"} />
      </Stack>
      <div className="tab-container">
        <div className="frontend-container">
          <div style={{ position: 'relative', width: "100%" }}>
            <form onSubmit={formik.handleSubmit} className="event-form-inner">
              <div className="event-form-head">
                <h2>Update "{editEventData.eventTitle}" Event</h2>
                <div className="btns">
                  <Button
                    icon="pi pi-angle-left"
                    className="custom-button"
                    label="Go Back"
                    onClick={cancelEvent}
                  />
                </div>
              </div>
              <div className="event-form-body list">
                <Box sx={{ position: 'relative' }}>
                  {isLoaded ? (
                    <GoogleMap
                      className="map-container"
                      mapContainerStyle={mapContainerStyle}
                      center={center}
                      zoom={18}
                    >
                      <MarkerF
                        position={markerPosition}
                        draggable={true}
                        onDragEnd={handleMarkerDragEnd}
                      />
                    </GoogleMap>
                  ) : (
                    <div>Loading...</div>
                  )}
                </Box>
                <div>
                  <TextField
                    type="hidden"
                    name="locationLat"
                    value={formik.values.locationLat}
                    sx={{ visibility: 'hidden' }}
                  />
                  <TextField
                    type="hidden"
                    name="locationLong"
                    value={formik.values.locationLong}
                    sx={{ visibility: 'hidden' }}
                  />

                  <TextField
                    name="eventDesc"
                    label="Whose event is it?"
                    value={formik.values.eventDesc}
                    onChange={handleFieldChange("eventDesc")} // Use common change handler
                    size="small"
                    variant="outlined"
                    sx={textFieldStyles}
                  />

                  <div style={{ marginBottom: "10px" }}>
                    <Calendar
                      baseZIndex={1100}
                      view="date"
                      minDate={today}
                      maxDate={oneYearFromToday}
                      //showButtonBar
                      showIcon
                      dateFormat="dd-M-yy"
                      value={formik.values.Date}
                      onChange={(e) => {
                        formik.setFieldValue("Date", e.value);
                        updateLocalStorage({
                          ...formik.values,
                          Date: e.value.toString(),
                        });
                      }}
                      placeholder="Event Start Date"
                      style={{ width: "100%", background: "transparent", border: "none" }}
                      inputStyle={{
                        fontWeight: '400',
                        backgroundColor: "transparent",
                        width: "100%",
                        border:
                          formik.touched.Date && formik.errors.Date
                            ? "1px solid red"
                            : "1px solid rgba(0, 0, 0, 0.23)",
                      }}
                      panelStyle={{ zIndex: 1100 }}
                      showTime hourFormat="12"
                      ref={calendarRef}
                      footerTemplate={(e) => <div className="p-datepicker-buttonbar"><button aria-label="OK" className="p-button-secondary p-button-text p-button p-component" type="button" onClick={closeCalender}><span className="p-button-label p-c">OK</span></button></div>}
                    />
                    {formik.touched.endDate && formik.errors.endDate && (<p className="MuiFormHelperText-root Mui-error MuiFormHelperText-sizeSmall MuiFormHelperText-contained">{formik.errors.Date}</p>)}
                  </div>

                  <div style={{ marginBottom: "10px" }}>
                    <Calendar
                      baseZIndex={1100}
                      view="date"
                      minDate={today}
                      maxDate={oneYearFromToday}
                      //showButtonBar
                      showIcon
                      dateFormat="dd-M-yy"
                      value={formik.values.endDate}
                      onChange={(e) => {
                        formik.setFieldValue("endDate", e.value);
                        updateLocalStorage({
                          ...formik.values,
                          endDate: e.value.toString(),
                        });
                      }}
                      placeholder="Event End Date"
                      style={{ width: "100%", background: "transparent", border: "none" }}
                      inputStyle={{
                        fontWeight: '400',
                        backgroundColor: "transparent",
                        width: "100%",
                        border:
                          formik.touched.endDate && formik.errors.endDate
                            ? "1px solid red"
                            : "1px solid rgba(0, 0, 0, 0.23)",
                      }}
                      panelStyle={{ zIndex: 1100 }}
                      showTime hourFormat="12"
                      ref={calendarRef}
                      footerTemplate={(e) => <div className="p-datepicker-buttonbar"><button aria-label="OK" className="p-button-secondary p-button-text p-button p-component" type="button" onClick={closeCalender}><span className="p-button-label p-c">OK</span></button></div>}
                    />
                    {formik.touched.endDate && formik.errors.endDate && (<p className="MuiFormHelperText-root Mui-error MuiFormHelperText-sizeSmall MuiFormHelperText-contained">{formik.errors.Date}</p>)}
                  </div>

                  <TextField
                    type="hidden"
                    name="area"
                    value={formik.values.area}
                    sx={{ visibility: 'hidden' }}
                  />
                  <TextField
                    type="hidden"
                    name="city"
                    value={formik.values.city}
                    sx={{ visibility: 'hidden' }}
                  />
                  <TextField
                    type="hidden"
                    name="state"
                    value={formik.values.state}
                    sx={{ visibility: 'hidden' }}
                  />
                  <TextField
                    disabled
                    name="Address"
                    label="Event Location/Venue"
                    value={formik.values.Address}
                    onChange={handleFieldChange("Address")} // Use common change handler
                    size="small"
                    variant="outlined"
                    multiline
                    rows={3}
                    sx={textFieldStyles}
                    error={formik.touched.Address && Boolean(formik.errors.Address)}
                    helperText="Please Use the Map marker Pin to Change address"
                  />
                  {error && <p style={{ color: "red" }}>{error}</p>}
                  <div className="btns group">
                    <Button
                      type="submit"
                      className="custom-button"
                      label="Update Event"
                    />
                    <Button
                      className="custom-button cancel"
                      label="Cancel"
                      onClick={cancelEvent}
                    />
                  </div>
                </div>
              </div>
              {/* <ConfirmDialog /> */}
            </form>
          </div>
        </div>
      </div>
      <Footer />
    </div >
  );
};

export default EventFormDashboardEdit;