import React, { useEffect, useState, useCallback } from 'react';
import {
  Box,
  TextField,
  Button,
  Chip,
  Paper
} from "@mui/material";
import { ConfirmDialog } from 'primereact/confirmdialog';
import { getServiceProviderProfile } from "../../Apiservices/Auth/servers/profileServer";
import './ServiceProviderDashboard.css';
import ServicesList from './ServicesList';

const ManageServicesTabContent = () => {
  const [providerServices, setProviderServices] = useState({ types: [] });
  const [loading, setLoading] = useState(true);

  const fetchServiceProviderServices = useCallback(async () => {
    setLoading(true);
    try {
      const { providerTypes } = await getServiceProviderProfile();
      setProviderServices({
        types: providerTypes,
      });
    } catch (error) {
      console.error("Failed to services:", error);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchServiceProviderServices();
  }, [fetchServiceProviderServices]);

  return (
    <>
      <ConfirmDialog />
      {providerServices.types.length > 0 ? (providerServices.types.map((t) => {
        return (
          <Paper key={t.id} sx={{ p: 2, mb: 2, backgroundColor: '#f8f8f8' }}>
            <div className='service-type'>
              <ServicesList providerTypeID={t.id} providerLabel={t.type} providerDesc={t.description}/>
            </div></Paper>
        )
      })) :
        <Paper sx={{ p: 2, backgroundColor: '#f8f8f8' }}>
          <div className='service-type'>
            <h4>Loading</h4>
          </div>
        </Paper>
      }
    </>
  )
}
export default ManageServicesTabContent;