import React from "react";
import "../css/AccountDialog.css";
import {Link} from 'react-router-dom'

function ServicesDialog({ isOpen, onClose }) {

    if (!isOpen) return null; // If dialog is not open, don't render

    return (
        <div className="account-dialog-overlay services-dialog">
            <div className="account-dialog-content">
                <div className="account-details">
                    <p className="account-d"><Link to={"/"}>AI Enabled Event Details</Link></p>
                    <p className="account-d"><Link to={"/"}>Invite</Link></p>
                    <p className="account-d"><Link to={"/"}>Hire a Professional (Priest)</Link></p>
                    <p className="account-d"><Link to={"/"}>Tent Houses</Link></p>
                    <p className="account-d"><Link to={"/"}>Book Catering</Link></p>
                </div>
            </div>
        </div>
    )
}

export default ServicesDialog