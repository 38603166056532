import React, { useCallback, useEffect, useState } from "react";
import {
    CssBaseline,
    Stack,
} from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useLocation, useNavigate } from "react-router-dom";
import Footer from "../Footer";
import ResponsiveAppBar from "../appBarcomponent/ResponsiveAppbar";
import "../eventComponent/EventTabs/EventTabs.css"
import { Button } from "primereact/button";
import { getServiceProvidersByID, fetchProviderData } from "../../Apiservices/Auth/servers/fetchProviderData";
import PhoneAndroidOutlinedIcon from '@mui/icons-material/PhoneAndroidOutlined';
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import RouteOutlinedIcon from '@mui/icons-material/RouteOutlined';
import LoadMap from "./LoadMap";


function EventServices() {
    const [loading, setLoading] = useState(true);
    const [providerData, setProviderData] = useState([]);
    const [providerInfo, setProviderInfo] = useState([]);
    const location = useLocation();
    const { serviceDetails, eventDetails } = location.state || {}; // Get event details

    console.log("eventDetails", eventDetails)

    const navigate = useNavigate();

    const backtoEvents = () => {
        navigate("/dashboard");
    }

    const fetchProviders = useCallback(async () => {
        setLoading(true);
        try {
            const provider_data = await fetchProviderData();
            setProviderData(provider_data);
        } catch (error) {
            console.error("Failed to fetch events:", error);
        } finally {
            setLoading(false);
        }
    }, []);

    const { id, serviceName, serviceDescription } = serviceDetails;
    const { locationLat, locationLong } = eventDetails;

    const fetchProviderList = useCallback(async () => {
        setLoading(true);
        try {
            const provider_data = await getServiceProvidersByID(id, locationLat, locationLong);
            setProviderInfo(provider_data);
        } catch (error) {
            console.error("Failed to fetch events:", error);
        } finally {
            setLoading(false);
        }
    }, [id]);

    useEffect(() => {
        fetchProviders();
        fetchProviderList();
    }, [id]);

    console.log("providerInfo", providerInfo)

    const handleEventService = (service) => {
        if (eventDetails && Object.keys(eventDetails).length > 0) {
            const info = {
                serviceDetails: service,
                eventDetails: eventDetails
            }
            navigate("/dashboard/event/service", { state: info });
            window.scrollTo({ top: 0, behavior: 'smooth' });
        }
    }

    const createEventHandle = () => {
        // setCreateEvent(false)
        // setIsFirstVisit(false)
        navigate("/dashboard/event/create");
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    const handleProviderServcies = (provider) =>{
        const info = {
            serviceDetails: serviceDetails,
            providerDetails: provider,
            eventDetails: eventDetails
        }
        navigate("/dashboard/provider/services", { state: info });
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }

    return (
        <div style={{ width: "100%" }}>
            <CssBaseline />
            <Stack spacing={7} sx={{ flexGrow: 1 }}>
                <ResponsiveAppBar params={"dashboard"} />
            </Stack>
            <div className="main-container">
                <div className="frontend-container">
                    <div style={{ width: '100%', paddingBottom: '30px' }}>
                        <div className="event-form-inner">
                            <div className="event-form-head">
                                <div className="event-service-head-inner">
                                    <img
                                        style={{
                                            display: "block",
                                            width: "50px",
                                        }}
                                        src={`${process.env.PUBLIC_URL}/assets/images/${id}.png`}
                                        alt={serviceName}
                                    />
                                    <div>
                                        <h3>{serviceName}</h3>
                                        <div className="event-rsvp">
                                            {serviceDescription}
                                        </div>
                                    </div>
                                </div>
                                <div className="btns group">
                                    <Button
                                        icon="pi pi-angle-left"
                                        className="custom-button"
                                        label="Go Back"
                                        onClick={backtoEvents}
                                    />
                                    <Button
                                        icon="pi pi-plus"
                                        className="custom-button"
                                        label="Create Event"
                                        onClick={createEventHandle}
                                    />
                                </div>
                            </div>
                            <div className="event-form-body list">
                                <div className="event-providers">
                                    {loading ? (
                                        <h3 style={{ textAlign: "center" }}><i className="pi pi-spin pi-spinner-dotted" style={{ fontSize: '2rem', color: '#0cc0df' }}></i></h3>
                                    ) : providerInfo.length > 0 && providerInfo.map((item) => {
                                        return (<div key={item.id} className="event-service" onClick={() => handleProviderServcies(item)}>
                                            {item.addresses.length > 0 && <LoadMap locationLat={item.addresses[0].latitude} locationLong={item.addresses[0].longitude} width="100px" height="100px"/>}
                                            <div className="event-service-head">
                                                <div className="event-service-headtop">
                                                    <h3>{item.firstName} {item.lastName}</h3>
                                                </div>
                                                <div>
                                                    <LocationOnOutlinedIcon className="plicon" />
                                                    {item.addresses.length > 0 ? item.addresses[0].formattedAddress : "Address not avaliable."}
                                                </div>
                                                <div className="bottom">
                                                    {item.distance !== null &&
                                                        <><RouteOutlinedIcon className="plicon" />
                                                            {`${(item.distance / 1000).toFixed(1)} km`}</>
                                                    } <PhoneAndroidOutlinedIcon className="plicon" />{item.mobileNumber}
                                                </div>
                                            </div>
                                            <div className="pi pi-chevron-right"></div>
                                        </div>
                                        );
                                    })}
                                </div>
                                <div className="event-service-list">
                                    {providerData.length > 0 && providerData.filter(item => item.id !== id).map((item) => <div key={item.id} className="event-service" onClick={() => handleEventService(item)}>
                                        <div className="event-service-head">
                                            <div>
                                                <div>
                                                    <h3>{item.serviceName}</h3>
                                                    <div className="event-rsvp">
                                                        {item.serviceDescription}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="pi pi-chevron-right"></div>
                                        </div>
                                    </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default EventServices