import config from '../../../config'
import { signupRequest, signupSuccess, signupFailure } from '../signUpAction';
export const verifyotp=(identifier)=>async(dispatch)=>{
    dispatch(signupRequest());
    console.log("otp fields:",identifier);
try{
const response=await fetch(`${config.API_BASE_URL}/auth/login-otp`,{
    method:"POST",
    headers:{'Content-Type':'application/json'},
    body:JSON.stringify(identifier)
});
 const data = await response.json();
 if(response.ok){
    console.log("send opt:",data);
    dispatch(signupSuccess(data));
 }else{
    throw new Error(data.message || 'failed to send otp');
 }
}catch(error){
   dispatch(signupFailure(error.message));
}
}