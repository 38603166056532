// src/store/authSlice.js
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { removeToken, setToken } from "../util/Cookies";
import { postPendingEvent } from "../services/AuthServices";
import { decodeToken } from "react-jwt";

// Define the async thunk
export const handlePendingEvent = createAsyncThunk(
  'auth/handlePendingEvent',
  async () => {
    await postPendingEvent();
  }
);

const authSlice = createSlice({
  name: "auth",
  initialState: {
    token: null,
    isLoggedIn: false,
    role: null,
    providerTypes: [],
  },
  reducers: {
    setCredentials: (state, action) => {
      console.log("Token set:", action.payload.token);
      state.token = action.payload.token;
      const decodedToken = decodeToken(state.token);
      state.isLoggedIn = true;
      state.role = decodedToken.userType; 
      state.providerTypes = decodedToken.providerTypes || []; // empty array if customer
      setToken(state.token);

    },
    logOut: (state) => {
      state.token = null;
      state.isLoggedIn = false;
      state.role = null;
      state.providerTypes = [];
      removeToken();
    },
  },
});

// Add an extra reducer to handle the pending event
authSlice.extraReducers = (builder) => {
  builder
    .addCase(handlePendingEvent.fulfilled, (state) => {
      console.log("Pending event posted after login");
    });
};

export const { setCredentials, logOut } = authSlice.actions;
export default authSlice.reducer;
