import axiosInstance from '../../../util/axiosInstance';
import { getToken } from "../../../util/Cookies";

export const editServiceProviderBasicInfo = async (info) => {
    const token = getToken();
    try {
        const response = await axiosInstance.put('/provider/profile', info, {
            method: "PUT",
            headers: {
                Authorization: `${token}`,
            }
        })
        if (response.status === 200) {
            return response.data;
        } else {
            throw new Error(response.message || 'Unable to get the provider details');
        }
    } catch (error) {
        if (error.response && error.response.status === 409) {
            return error.response.data;
        } else {
            console.error('There was a problem with the fetch operation:', error);
        }

    }
};

export const addServiceProviderAddressInfo = async (info) => {
    const token = getToken();
    try {
        const response = await axiosInstance.post('/provider/profile/address', info, {
            method: "POST",
            headers: {
                Authorization: `${token}`,
            }
        })
        if (response.status === 200) {
            return response.data;
        } else {
            throw new Error(response.message || 'Unable to get the provider details');
        }
    } catch (error) {
        if (error.response && error.response.status === 409) {
            return error.response.data;
        } else {
            console.error('There was a problem with the fetch operation:', error);
        }
    }
};

export const editServiceProviderAddressInfo = async (info, addressID) => {
    const token = getToken();
    try {
        const response = await axiosInstance.put(`/provider/profile/address/${addressID}`, info, {
            method: "PUT",
            headers: {
                Authorization: `${token}`,
            }
        })
        if (response.status === 200) {
            return response.data;
        } else {
            throw new Error(response.message || 'Unable to get the provider details');
        }
    } catch (error) {
        if (error.response && error.response.status === 409) {
            return error.response.data;
        } else {
            console.error('There was a problem with the fetch operation:', error);
        }
    }
};

export const changeServiceProviderPassword = async (info) => {
    const token = getToken();
    try {
        const response = await axiosInstance.post('/auth-providers/change-password', info, {
            method: "POST",
            headers: {
                Authorization: `${token}`,
            }
        })
        if (response.status === 200) {
            return response.data;
        } else {
            throw new Error('Unable to get the provider details');
        }
    } catch (error) {
        if (error.response && error.response.status === 409) {
            return error.response.data;
        } else {
            console.error('There was a problem with the fetch operation:', error);
        }
    }
};

export const sendVerificationOTP = async (identifier) => {
    try {
        const response = await axiosInstance.post("/auth-providers/send-verification-otp", {
            identifier,
        });
        return response.data;
    } catch (error) {
        if (error.response) {
            throw new Error(error.response.data.message); // Throw error message from server
        } else {
            throw new Error("Something Went Wrong"); // General error message
        }
    }
};

export const verifyWithOTP = async (values) => {
    try {
        const response = await axiosInstance.post("/auth-providers/verify-otp", values);
        return response.data;
    } catch (error) {
        if (error.response) {
            throw new Error(error.response.data.message); // Throw error message from server
        } else {
            throw new Error("Something Went Wrong"); // General error message
        }
    }
};