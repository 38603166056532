import React, { useState } from "react";
import eventsvideo from "../../assets/videos/events3.mp4";
import "./CreateEventSection.css";
import EventForm from "../eventComponent/EventForm2";

const CreateEventSection = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = (event) => {
    event.preventDefault();
    setIsSubmitting(true);

    // Simulate a form submission delay (for demonstration)
    setTimeout(() => {
      setIsSubmitting(false);
    }, 2000);
  };

  return (
    <div className="video-container">
      <video autoPlay loop muted className="video-background">
        <source src={eventsvideo} type="video/mp4" />
        Sorry Your browser is not supporting the Videoplay.
      </video>
      <div className="video-container-contents">
        {/* Left side message */}
        <div className="frontend-container">
          <div className="video-container-inner">
            <div className="message">
              AI-enabled event hosting (at home) solution helps to
              invite and manage guests, hire professionals (priest),
              book rentals (tent, tables, and chairs), order catering,
              and manage all event needs.
            </div>
            <EventForm></EventForm>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateEventSection;
