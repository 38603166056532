import React, { useEffect, useState, useCallback } from 'react';
import { format } from 'date-fns';
import { useFormik } from 'formik';
import * as Yup from "yup";
import { styled } from '@mui/material/styles';
import {
    Box,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Autocomplete,
    TextField,
    Button,
    Paper
} from "@mui/material";
import { tableCellClasses } from '@mui/material/TableCell';
import { Calendar } from "primereact/calendar";
import { Dialog } from "primereact/dialog";
import { confirmDialog } from 'primereact/confirmdialog';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import CancelTwoToneIcon from '@mui/icons-material/CancelTwoTone';
import CloseTwoToneIcon from '@mui/icons-material/CloseTwoTone';
import AddTwoToneIcon from '@mui/icons-material/AddTwoTone';
import { fetchServices } from '../../Apiservices/Auth/servers/fetchServices';
import { getServiceProviderProfile } from "../../Apiservices/Auth/servers/profileServer";
import './ServiceProviderDashboard.css';
import { Height, Style } from '@mui/icons-material';
import { updateServiceProviderServices, addServiceProviderServices, editServiceProviderServices } from '../../Apiservices/Auth/servers/updateServiceProviderServices';
import { getServiceProviderServices } from '../../Apiservices/Auth/servers/fetchProviderData';

function ServicesList(props) {

    const [providerServices, setProviderServices] = useState([]);
    const [resultedServices, setResultedServices] = useState([]);
    const [editService, setEditService] = useState({});
    const [loading, setLoading] = useState(true);
    const today = new Date();
    const oneYearFromToday = new Date(
        new Date().setFullYear(today.getFullYear() + 1)
    );

    const [isAddServiceDialogVisible, setAddServiceIsDialogVisible] = useState(false);
    const [isEditServiceDialogVisible, setEditServiceIsDialogVisible] = useState(false);

    const openAddServiceDialog = () => {
        setAddServiceIsDialogVisible(true);
    };

    const closeAddServiceDialog = () => {
        setAddServiceIsDialogVisible(false);
    };

    const openEditServiceDialog = () => {
        setEditService({})
        setEditServiceIsDialogVisible(true);
    };

    const closeEditServiceDialog = () => {
        setEditService({})
        setEditServiceIsDialogVisible(false);
    };

    const fetchServiceProviderServices = useCallback(async () => {
        setLoading(true);
        try {
            const data = await getServiceProviderServices();
            setProviderServices(data ? data.services : []);
        } catch (error) {
            console.error("Failed to services:", error);
        } finally {
            setLoading(false);
        }
    }, []);

    useEffect(() => {
        fetchServiceProviderServices();
    }, [fetchServiceProviderServices]);

    console.log("providerServices", providerServices)

    function subtractArraysOfObjects(array1, array2) {
        const array2Keys = array2.map(obj => obj['service_master_id']);
        return array1.filter(obj => !array2Keys.includes(obj['id']));
    }

    const resultedServicesFunc = useCallback(async () => {
        setLoading(true);
        try {
            const providerData = await getServiceProviderServices();
            const serviceList = await fetchServices(props.providerTypeID);

            if (providerData !== undefined) {
                const enabledServices = providerData.services.filter((item) => {
                    return item.serviceMaster.providerTypeId === props.providerTypeID
                })
                const result = await subtractArraysOfObjects(serviceList, enabledServices);
                setResultedServices(result);
            } else {
                setResultedServices(serviceList);
            }
        } catch (error) {
            console.error("Failed to services:", error);
        } finally {
            setLoading(false);
        }
    }, []);

    useEffect(() => {
        resultedServicesFunc();
    }, [resultedServicesFunc]);

    console.log("resultedServices", resultedServices)

    const handleDelete = async (itemId) => {
        console.log("Delete", itemId);
        const deleteServices = JSON.stringify({
            "toAddService": [],
            "toDeleteService": [itemId]
        });
        setLoading(true);
        try {
            const response = await updateServiceProviderServices(deleteServices);
            console.log(response)
        } catch (error) {
            console.error("Failed to services:", error);
        } finally {
            setLoading(false);
            resultedServicesFunc()
            fetchServiceProviderServices()
        }
    };

    const deleteServiceConfirm = (serviceID) => {
        confirmDialog({
            message: 'Do you want to delete this service?',
            header: 'Delete Confirmation',
            defaultFocus: 'reject',
            acceptClassName: 'p-button-danger',
            accept: () => handleDelete(serviceID),
            reject
        });
    };

    const reject = () => {}

    const handleEdit = async (obj) => {
        setEditService(obj)
        setEditServiceIsDialogVisible(true);
    }

    //console.log("editService", editService);

    function removeBlankAttributes(obj) {
        const result = {};
        for (const key in obj) {
            if (obj[key] !== '' && obj[key] !== undefined) {
                result[key] = obj[key];
            }
        }
        return result;
    }

    function replaceEmptyWithNull(obj) {
        const newObj = {};
        for (const key in obj) {
            if (obj.hasOwnProperty(key)) {
                const value = obj[key];
                if ((typeof value === 'string' && value.trim() === '') || value === undefined) {
                    newObj[key] = null;
                } else {
                    newObj[key] = value;
                }
            }
        }

        return newObj;
    }

    const formik = useFormik({
        initialValues: {
            serviceId: "",
            price: "",
            discountPrice: "",
            discountValidUpto: ""
        },
        validationSchema: Yup.object({
            serviceId: Yup.string().required("Service is required"),
            price: Yup.number().integer("Price should be number").required("Price is required")
        }),
        onSubmit: async (values, { resetForm }) => {
            const altered = removeBlankAttributes(values);
            const services = [altered];
            const activateServices = JSON.stringify({ services });
            //console.log("activateServices", activateServices)
            setLoading(true);
            try {
                const response = await addServiceProviderServices(activateServices);
            } catch (error) {
                console.error("Failed to services:", error);
            } finally {
                setLoading(false);
                resultedServicesFunc()
                fetchServiceProviderServices()
                setAddServiceIsDialogVisible(false);
                setEditServiceIsDialogVisible(false);
                resetForm();
            }
        }
    });

    const formik2 = useFormik({
        enableReinitialize: true,
        initialValues: {
            serviceId: editService?.service_master_id,
            price: editService?.price ? Number(editService?.price) : "",
            discountPrice: editService?.discountPrice ? Number(editService?.discountPrice) : "",
            discountValidUpto: editService?.discountValidUpto ? new Date(editService?.discountValidUpto) : ""
        },
        validationSchema: Yup.object({
            price: Yup.number().integer("Price should be number").required("Price is required")
        }),
        onSubmit: async (values, { resetForm }) => {
            values.serviceId = editService?.service_master_id;
            const altered = replaceEmptyWithNull(values);
            const services = [altered];
            const editServices = JSON.stringify({ services });

            setLoading(true);
            try {
                const response = await editServiceProviderServices(editServices);
            } catch (error) {
                console.error("Failed to services:", error);
            } finally {
                setLoading(false);
                resultedServicesFunc()
                fetchServiceProviderServices()
                setAddServiceIsDialogVisible(false);
                setEditServiceIsDialogVisible(false);
                resetForm();
            }
        }
    });

    // Common method to update local storage
    /* const updateLocalStorage = (updatedValues) => {
        if (!isLoggedIn) {
            console.log("Is not logged In ", updatedValues);
            localStorage.setItem(eventLocalStorageKey, JSON.stringify(updatedValues));
        } else {
            console.log("Is logged In ");
        }
    }; */

    const handleFieldChange = (field) => (event) => {
        const value = event.target.value;
        formik.setFieldValue(field, value);
        //updateLocalStorage({ ...formik.values, [field]: value });
    };
    const handleFieldChange2 = (field) => (event) => {
        const value = event.target.value;
        formik2.setFieldValue(field, value);
        //updateLocalStorage({ ...formik.values, [field]: value });
    };
    const handleServiceChange = (value) => {
        const service = resultedServices.find((e) => e.id === value) || "";
        // Update Formik
        formik.setFieldValue('serviceId', service.id);
    };

    const textFieldStyles = {
        backgroundColor: "#ffffff",
        width: "100%",
        borderRadius: '4px',
        marginBottom: '10px'
    };
    const StyledTableCell = styled(TableCell)(() => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: '#0cc0df',
            color: '#FFFFFF',
            fontSize: 16,
            fontWeight: 700
        }
    }));

    return (
        <>
            <Box sx={{
                textAlign: 'left',
                display: 'flex',
                gap: '20px',
                alignItems: {
                    xs: 'flex-start',
                    md: 'flex-end'
                }, justifyContent: 'space-between',
                flexDirection: {
                    xs: 'column',
                    md: 'row'
                }
            }}>
                <div>
                    <h4>{props.providerLabel}</h4>
                    <div className='desc'>{props.providerDesc}</div>
                </div>
                {resultedServices.length > 0 && <div>
                    <Button
                        size='small'
                        onClick={openAddServiceDialog}
                        startIcon={<AddTwoToneIcon />}
                        sx={{
                            fontWeight: '700',
                            padding: '4px 16px',
                            color: "#ffffff",
                            border: '2px solid #0cc0df',
                            backgroundColor: "#0cc0df",
                            "&:hover": {
                                borderColor: "#1da3ba",
                                backgroundColor: "#1da3ba"
                            }
                        }}>
                        Add New Service
                    </Button>
                    <Dialog header="Add New Service" visible={isAddServiceDialogVisible} onHide={closeAddServiceDialog}>
                        <div className="p-fluid">
                            <div className='serviceForm'>
                                {/* Form Fields */}
                                <form id={`ServiceAddForm${props.providerTypeID}`} onSubmit={formik.handleSubmit}>
                                    <Autocomplete
                                        //multiple
                                        freeSolo
                                        id={`serviceId${props.providerTypeID}`}
                                        options={resultedServices}
                                        size="small"
                                        getOptionLabel={(option) => option?.serviceName || ""}
                                        onChange={(event, value) => {
                                            handleServiceChange(value?.id);
                                        }}
                                        noOptionsText={'None service are available to select'}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                variant="outlined"
                                                label={`Select ${props.providerLabel} Service`}
                                                sx={textFieldStyles}
                                                error={formik.touched.serviceId && Boolean(formik.errors.serviceId)}
                                                helperText={formik.touched.serviceId && formik.errors.serviceId}
                                            />
                                        )}
                                    />
                                    <TextField
                                        name="price"
                                        label="Enter Price"
                                        value={formik.values.price}
                                        onChange={handleFieldChange("price")} // Use common change handler
                                        size="small"
                                        variant="outlined"
                                        sx={textFieldStyles}
                                        error={formik.touched.price && Boolean(formik.errors.price)}
                                        helperText={formik.touched.price && formik.errors.price}
                                    />
                                    <TextField
                                        name="discountPrice"
                                        label="Enter Discount Price"
                                        value={formik.values.discountPrice}
                                        onChange={handleFieldChange("discountPrice")} // Use common change handler
                                        size="small"
                                        variant="outlined"
                                        sx={textFieldStyles}
                                    />
                                    <Calendar
                                        baseZIndex={1100}
                                        view="date"
                                        minDate={today}
                                        maxDate={oneYearFromToday}
                                        showButtonBar
                                        showIcon
                                        dateFormat="dd-M-yy"
                                        value={formik.values.discountValidUpto}
                                        onChange={(e) => {
                                            formik.setFieldValue("discountValidUpto", e.value ? e.value : "");
                                        }}
                                        placeholder="Discount Valid upto"
                                        panelStyle={{ zIndex: 1100 }}
                                    />
                                    <Button
                                        type="submit"
                                        sx={{
                                            width: '100%',
                                            height: '40px',
                                            color: "#ffffff",
                                            backgroundColor: "#0cc0df",
                                            "&:hover": {
                                                backgroundColor: "#1da3ba",
                                            },
                                        }}
                                    >
                                        Submit
                                    </Button>
                                </form>
                                {/* Form Fields */}
                            </div>
                        </div>
                    </Dialog>
                </div>}
                {Object.keys(editService).length > 0 && <Dialog header={`Update "${editService.serviceMaster.serviceName}" Service`} visible={isEditServiceDialogVisible} onHide={closeEditServiceDialog}>
                    <div className="p-fluid">
                        <div className='serviceForm'>
                            {/* Form Fields */}
                            <form id={`ServiceEditForm${props.providerTypeID}`} onSubmit={formik2.handleSubmit}>
                                <TextField
                                    name="price"
                                    label="Enter Price"
                                    value={formik2.values.price}
                                    onChange={handleFieldChange2("price")} // Use common change handler
                                    size="small"
                                    variant="outlined"
                                    sx={textFieldStyles}
                                    error={formik2.touched.price && Boolean(formik2.errors.price)}
                                    helperText={formik2.touched.price && formik2.errors.price}
                                />
                                <TextField
                                    name="discountPrice"
                                    label="Enter Discount Price"
                                    value={formik2.values.discountPrice}
                                    onChange={handleFieldChange2("discountPrice")} // Use common change handler
                                    size="small"
                                    variant="outlined"
                                    sx={textFieldStyles}
                                />
                                <Calendar
                                    baseZIndex={1100}
                                    view="date"
                                    minDate={today}
                                    maxDate={oneYearFromToday}
                                    showButtonBar
                                    showIcon
                                    dateFormat="dd-M-yy"
                                    value={formik2.values.discountValidUpto}
                                    defaultDate={formik2.values.discountValidUpto}
                                    onChange={(e) => {
                                        formik2.setFieldValue("discountValidUpto", e.value ? e.value : "");
                                    }}
                                    placeholder="Discount Valid upto"
                                    panelStyle={{ zIndex: 1100 }}
                                />
                                <Button
                                    type="submit"
                                    sx={{
                                        width: '100%',
                                        height: '40px',
                                        color: "#ffffff",
                                        backgroundColor: "#0cc0df",
                                        "&:hover": {
                                            backgroundColor: "#1da3ba",
                                        },
                                    }}
                                >
                                    Submit
                                </Button>
                            </form>
                            {/* Form Fields */}
                        </div>
                    </div>
                </Dialog>}
            </Box>
            <div className='services-selection'>
                <TableContainer component={Paper}>
                    <Table size="small" aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell sx={{ display:{xs: 'none', md: 'table-cell'} }}>Service</StyledTableCell>
                                <StyledTableCell sx={{ width: '210px', display:{xs: 'none', md: 'table-cell'} }}>Price</StyledTableCell>
                                <StyledTableCell sx={{ width: '210px', display:{xs: 'none', md: 'table-cell'} }}>Discount Price</StyledTableCell>
                                <StyledTableCell sx={{ width: '255px', display:{xs: 'none', md: 'table-cell'} }}>Discount valid upto</StyledTableCell>
                                <StyledTableCell sx={{ display:{xs: 'none', md: 'table-cell'} }} align='center'>Action</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {(providerServices.length > 0 && providerServices.find(item => item.serviceMaster.providerTypeId === props.providerTypeID)) ? (providerServices.filter((s) => s.serviceMaster.providerTypeId === props.providerTypeID).map((s) => {
                                return (
                                    <TableRow className="td-header-row" key={s.serviceMaster.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell className='td-header-top' sx={{ whiteSpace: 'nowrap' }}>{s.serviceMaster.serviceName}<br/><small>{s.serviceMaster.description}</small></TableCell>
                                        <TableCell><span className="td-header">Price</span>{s.price}</TableCell>
                                        <TableCell><span className="td-header">Discount Price</span>{s.discountPrice ? s.discountPrice : 'NA'}</TableCell>
                                        <TableCell><span className="td-header">Discount valid upto</span>{s.discountValidUpto ? format(new Date(s.discountValidUpto), "MMMM dd, yyyy") : 'NA'}</TableCell>
                                        <TableCell sx={{ width: '76px' }}><span className="td-header">Action</span><div style={{ display: 'flex', gap: '10px' }}><div className='action edit' onClick={() => handleEdit(s)}><EditTwoToneIcon /></div><div className='action delete' onClick={() => deleteServiceConfirm(s.serviceMaster.id)} aria-controls={'dlg_confirmation'} aria-expanded={true}><CloseTwoToneIcon /></div></div></TableCell>
                                    </TableRow>
                                )
                            })) : (<TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            ><TableCell colSpan={6}><h3 style={{ textAlign: 'center', padding: '15px' }}>{`No ${props.providerLabel} services found`}</h3></TableCell></TableRow>)}

                        </TableBody>
                    </Table>
                </TableContainer>
                {loading && <div className='loading'></div>}
            </div>
        </>
    )
}

export default ServicesList