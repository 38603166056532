import React, { useCallback, useEffect, useState } from "react";
import { format } from 'date-fns';
import {
    CssBaseline,
    Stack,
    Box
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import Footer from "../Footer";
import ResponsiveAppBar from "../appBarcomponent/ResponsiveAppbar";
import "../eventComponent/EventTabs/EventTabs.css"
import { Button } from "primereact/button";
import { getProviderServicesByID, fetchProviderData } from "../../Apiservices/Auth/servers/fetchProviderData";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import PhoneAndroidOutlinedIcon from '@mui/icons-material/PhoneAndroidOutlined';
import LoadMap from "./LoadMap";

function EventProviderServices() {
    const [loading, setLoading] = useState(true);
    const [providerData, setProviderData] = useState([]);
    const [providerServices, setProviderServices] = useState([]);
    const location = useLocation();
    const { serviceDetails, providerDetails, eventDetails } = location.state || {}; // Get event details


    const navigate = useNavigate();

    const backtoEvents = () => {
        const info = {
            serviceDetails: serviceDetails,
            eventDetails: eventDetails
        }
        navigate("/dashboard/event/service", { state: info });
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }

    const { id, firstName, lastName, addresses, mobileNumber } = providerDetails;

    const fetchProviders = useCallback(async () => {
        setLoading(true);
        try {
            const provider_data = await fetchProviderData();
            setProviderData(provider_data);
        } catch (error) {
            console.error("Failed to fetch events:", error);
        } finally {
            setLoading(false);
        }
    }, []);

    const fetchProviderServices = useCallback(async () => {
        setLoading(true);
        try {
            const provider_data = await getProviderServicesByID(id);
            setProviderServices(provider_data.services);
        } catch (error) {
            console.error("Failed to fetch events:", error);
        } finally {
            setLoading(false);
        }
    }, [id]);

    useEffect(() => {
        fetchProviders();
        fetchProviderServices();
    }, []);


    const handleEventService = (service) => {
        if (eventDetails && Object.keys(eventDetails).length > 0) {
            const info = {
                serviceDetails: service,
                eventDetails: eventDetails
            }
            navigate("/dashboard/event/service", { state: info });
            window.scrollTo({ top: 0, behavior: 'smooth' });
        }
    }

    const createEventHandle = () => {
        navigate("/dashboard/event/create");
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    return (
        <div style={{ width: "100%" }}>
            <CssBaseline />
            <Stack spacing={7} sx={{ flexGrow: 1 }}>
                <ResponsiveAppBar params={"dashboard"} />
            </Stack>
            <div className="main-container">
                <div className="frontend-container">
                    <div style={{ width: '100%', paddingBottom: '30px' }}>
                        <div className="event-form-inner">
                            <div className="event-form-head">
                                <div className="event-service-head-inner">
                                    <img
                                        style={{
                                            display: "block",
                                            width: "50px",
                                        }}
                                        src={`${process.env.PUBLIC_URL}/assets/images/${serviceDetails.id}.png`}
                                        alt={serviceDetails.serviceName}
                                    />
                                    <div>
                                        <h3>{serviceDetails.serviceName}</h3>
                                        <div className="event-rsvp">
                                            {serviceDetails.serviceDescription}
                                        </div>
                                    </div>
                                </div>
                                <div className="btns group">
                                    <Button
                                        icon="pi pi-angle-left"
                                        className="custom-button"
                                        label="Go Back"
                                        onClick={backtoEvents}
                                    />
                                    <Button
                                        icon="pi pi-plus"
                                        className="custom-button"
                                        label="Create Event"
                                        onClick={createEventHandle}
                                    />
                                </div>
                            </div>
                            <div className="event-form-body list2">
                                <div className="event-service-list">
                                    <div className="event-service alter">
                                        <div className="event-service-head" style={{ flexDirection: 'column', alignItems: 'flex-start', gap: '15px' }}>

                                            <h3>{firstName} {lastName}</h3>

                                            <LoadMap locationLat={addresses[0].latitude} locationLong={addresses[0].longitude} width="100%" height="180px" />

                                            <div>
                                                <LocationOnOutlinedIcon className="plicon" />
                                                {addresses[0].formattedAddress}
                                            </div>
                                            <div>
                                                <PhoneAndroidOutlinedIcon className="plicon" />
                                                {mobileNumber}
                                            </div>
                                        </div>
                                    </div>
                                    <Box sx={{ display: { xs: 'none', md: 'flex' }, flexDirection: 'column', gap: '15px' }}>
                                        {providerData.length > 0 && providerData.map((item) => <div key={item.id} className="event-service" onClick={() => handleEventService(item)}>
                                            <div className="event-service-head">
                                                <div>
                                                    <div>
                                                        <h3>{item.serviceName}</h3>
                                                        <div className="event-rsvp">
                                                            {item.serviceDescription}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="pi pi-chevron-right"></div>
                                            </div>
                                        </div>
                                        )}
                                    </Box>
                                </div>
                                <div className="event-providerservcies">
                                    {loading ? (
                                        <h3 style={{ width: '100%', textAlign: "center" }}><i className="pi pi-spin pi-spinner-dotted" style={{ fontSize: '2rem', color: '#0cc0df' }}></i></h3>
                                    ) : providerServices.length > 0 ? providerServices.filter(item => item.serviceMaster.providerTypeId === serviceDetails.id).map((item) => {
                                        return (<div key={item.serviceMaster.id} className="event-service alter">
                                            <div className="event-service-headtop">
                                                <h3>{item.serviceMaster.serviceName}</h3>

                                                <div style={{ marginBottom: '10px' }} className="desc">{item.serviceMaster.description}</div>
                                                {item.discountPrice ? <div className="price"><s>&#8377;{item.price}</s><br /><strong>&#8377;{item.discountPrice}</strong><span style={{ color: 'red', fontSize: '20px' }}>*</span></div> : <div className="price"><strong>&#8377;{item.price}</strong></div>}
                                                {item.discountValidUpto && item.discountPrice && <div className="validupto"><span style={{ color: 'red', fontSize: '20px' }}>*</span> Valid upto {format(new Date(item.discountValidUpto), "MMMM dd, yyyy")}</div>}
                                            </div>
                                            <div className="event-service-middle">
                                                <Button
                                                    icon="pi pi-plus"
                                                    className="custom-button"
                                                    label="Add Service"
                                                    size="small"
                                                />
                                            </div>
                                        </div>
                                        );
                                    }) : <h4 style={{ width: '100%' }} className="text-center">We are not currently offering {serviceDetails.serviceName} services</h4>}
                                </div>
                            </div>
                            <Box className="event-service-list" sx={{ display: { xs: 'flex', md: 'none' }, marginTop: '15px', textAlign: 'left' }}>
                                {providerData.length > 0 && providerData.map((item) => <div key={item.id} className="event-service" onClick={() => handleEventService(item)}>
                                    <div className="event-service-head">
                                        <div>
                                            <div>
                                                <h3>{item.serviceName}</h3>
                                                <div className="event-rsvp">
                                                    {item.serviceDescription}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="pi pi-chevron-right"></div>
                                    </div>
                                </div>
                                )}
                            </Box>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default EventProviderServices