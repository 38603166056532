import React from 'react'
import { Link } from 'react-router-dom'

function MiddleContent({ params }) {
    return (
        <div className="middle-content">
            <div className="frontend-container">
                <div style={{ width: '100%' }}>
                    <h2>Enjoy your events with friends & family.<br /> We take care of Hosting.</h2>
                    <div className="middle-content-wrapper">
                        {params !== "dashboard" && <div className="middle-content-left">
                            <h3>Get Started</h3>
                            <div className="middle-content-sec">
                                <Link className="card">
                                    <div>
                                        <h4>AI Enabled Event Details</h4>
                                        <h5>KNOW ABOUT YOUR EVENT</h5>
                                        <div className='desc'>Get to know what to do exactly (AI enabled).</div>
                                    </div>
                                    <div className="pi pi-chevron-right"></div>
                                </Link>
                                <Link to={"/"} className="card">
                                    <div>
                                        <h4>Invite</h4>
                                        <h5>EASY GUEST MANAGEMENT</h5>
                                        <div className='desc'>Invite and manage your guests.</div>
                                    </div>
                                    <div className="pi pi-chevron-right"></div>
                                </Link>
                                <Link className="card">
                                    <div>
                                        <h4>Hire a Professional (Priest)</h4>
                                        <h5>CHOOSE THE RIGHT PRIEST</h5>
                                        <div className='desc'>Book expert priest from multiple list.</div>
                                    </div>
                                    <div className="pi pi-chevron-right"></div>
                                </Link>
                                <Link className="card">
                                    <div>
                                        <h4>Tent Houses</h4>
                                        <h5>BOOK RENTALS LIKE TENTS & CHAIRS</h5>
                                        <div className='desc'>Select and book tables, chairs, plates, tents etc. from vendors.</div>
                                    </div>
                                    <div className="pi pi-chevron-right"></div>
                                </Link>
                                <Link className="card">
                                    <div>
                                        <h4>Book Catering</h4>
                                        <h5>ORDER THE RIGHT FOOD</h5>
                                        <div className='desc'>Book best of the caterers from multiple list.</div>
                                    </div>
                                    <div className="pi pi-chevron-right"></div>
                                </Link>
                            </div>
                        </div>}
                        <div className="middle-content-right">
                        <h3>Select Event</h3>
                            <div className={`middle-content-sec ${params === "dashboard" && 'full'}`}>
                                <Link to={"/social-gatherings"} className="card">
                                    <div>
                                        <h4>Social Gatherings</h4>
                                        <div className='desc'>Get to know what to do exactly (AI enabled).</div>
                                    </div>
                                    <div className="pi pi-chevron-right"></div>
                                </Link>
                                <Link to={"/celebrations"} className="card">
                                    <div>
                                        <h4>Celebrations</h4>
                                        <div className='desc'>Invite and manage your guests.</div>
                                    </div>
                                    <div className="pi pi-chevron-right"></div>
                                </Link>
                                <Link to={"/religious-or-cultural-ceremonies"} className="card">
                                    <div>
                                        <h4>Religious or Cultural Ceremonies</h4>
                                        <div className='desc'>Book expert priest from multiple list.</div>
                                    </div>
                                    <div className="pi pi-chevron-right"></div>
                                </Link>
                                <Link to={"/personal-or-family-events"} className="card">
                                    <div>
                                        <h4>Personal or Family Events</h4>
                                        <div className='desc'>Select and book tables, chairs, plates, tents etc. from vendors.</div>
                                    </div>
                                    <div className="pi pi-chevron-right"></div>
                                </Link>
                            </div>
                        </div>
                    </div>
                    {/* <ul className="features">
              <li><i className="pi pi-chevron-right" style={{ color: '#0cc0df' }}></i><span><strong>Browse and Order Easily:</strong> Our app allows you to explore a variety of rental options and place your order in just a few clicks.</span></li>

              <li><i className="pi pi-chevron-right" style={{ color: '#0cc0df' }}></i><span><strong>Connect with Trusted Suppliers:</strong> We partner with reliable suppliers to ensure you receive top-notch service and products.</span></li>

              <li><i className="pi pi-chevron-right" style={{ color: '#0cc0df' }}></i><span><strong>Save Time and Effort:</strong> No more endless searches or complicated booking processes – Thurent.com streamlines everything for you.</span></li>

              <li><i className="pi pi-chevron-right" style={{ color: '#0cc0df' }}></i><span><strong>Enjoy Your Events:</strong> Focus on creating memorable moments with your guests while we take care of the logistics</span></li>
            </ul> */}
                </div>
            </div>
        </div>
    )
}

export default MiddleContent