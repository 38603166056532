import React from 'react';
import { Link, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from "react-redux";

import { logOut } from '../store/authSlice';

function MiddleComponent() {
  const dispatch = useDispatch();
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);

  const navigate = useNavigate();

  const loginpag = () => {
    navigate("/login");
  };
  const logoutAccount = () => {
    dispatch(logOut()); // Dispatch the logout action to clear user state
    // navigate("/"); // Redirect the user to the login page after logging out
  };

  return (
      <div className='frontend-container'>
        <div className='footer-content'>
          <div className='footer-content-wrapper'>
            <div className='section'>
              <h3>Services</h3>
              <p><strong>KNOW ABOUT YOUR EVENT:</strong>Get to know what to do exactly (AI enabled).</p>
              <p><strong>EASY GUEST MANAGEMENT:</strong>Invite and manage your guests.</p>
              <p><strong>CHOOSE THE RIGHT PRIEST:</strong>Book expert priest from multiple list.</p>
              <p><strong>BOOK RENTALS LIKE TENTS & CHAIRS:</strong>Select and book tables, chairs, plates, tents etc. from vendors.</p>
              <p><strong>ORDER THE RIGHT FOOD:</strong>Book best of the caterers from multiple list.</p>
            </div>
            <div className='section contact'>
              <h3>Contact</h3>
              <p><i className="pi pi-phone"></i><span>8588633504</span></p>
              <p><i className="pi pi-map-marker"></i><span>THURENT TECH SERVICES PRIVATE LIMITED<br />1-8-19/2, New Dilsukhnagar , Hyderabad- 500060 TELANGANA INDIA</span></p>
              <p><i className="pi pi-envelope"></i><span>nick@greenbirdmedia.com</span></p>
            </div>
            <div className='section'>
              <h3>Quick Links</h3>
              <ul>
                <li><Link to={'/'}>Home</Link></li>
                <li><Link to={'/#aboutus'}>About Us</Link></li>
                <li><Link to={'/#contact'}>Contact</Link></li>
                {!isLoggedIn ? <li><span
                  onClick={loginpag}
                  style={{
                    cursor: "pointer",
                  }}
                >Log-in / Sign-Up</span></li> : 
                <li><span
                onClick={logoutAccount}
                  style={{
                    cursor: "pointer",
                  }}
                >Logout</span></li>}
              </ul>
            </div>
          </div>
        </div>
      </div>
  );
}
export default MiddleComponent;
