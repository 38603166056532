import { createTheme } from '@mui/material/styles';

const theme = createTheme({
    typography: {
        "fontFamily": `"Raleway", sans-serif`,
        "fontSize": 16,
        "fontWeight": 600,
    },
    components: {
        MuiInput: {
            styleOverrides: {
                root: {
                    color: 'red',
                    fontSize: '1rem',
                },
            },
        }
    }
})

export default theme;