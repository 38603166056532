import React from 'react'
import './Howdoesitwork.css';  // Custom CSS for circular layout

function Howdoesitwork() {
    return (
        <div className="frontend-container howdoesitwork">
            <div className='hdwork'>
                <div className='header'>
                    <div className='header-circle'>
                        <div className='header-circle-outer'>
                            <div className='header-circle-inner'>
                                <h1>How Does it Work</h1>
                            </div>
                        </div>
                        <div className='indicator create'></div>
                        <div className='indicator invite'></div>
                        <div className='indicator select'></div>
                        <div className='indicator payconfirm'></div>
                    </div>
                </div>
                <div className='steps'>
                    <div className='step create'>
                        <div className='num'>1</div>
                        <div className='desc'>
                            <h2>Create</h2>
                            <p>Create an Event (or) Select from Packages</p>
                        </div>
                    </div>
                    <div className='step invite'>
                        <div className='num'>2</div>
                        <div className='desc'>
                            <h2>Invite</h2>
                            <p>Invite Friends/Family via WhatsApp</p>
                        </div>
                    </div>
                    <div className='step select'>
                        <div className='num'>3</div>
                        <div className='desc'>
                            <h2>Select</h2>
                            <p>Select Provider from the List | Check Reviews, Ratings, and Price etc.</p>
                        </div>
                    </div>
                    <div className='step payconfirm'>
                        <div className='num'>4</div>
                        <div className='desc'>
                            <h2>Pay & Confirm</h2>
                            <p>Pay Online & Confirm Order | Service Delivered on Time at Home</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Howdoesitwork