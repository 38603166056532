import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Box, Skeleton, Typography } from "@mui/material";
import { Avatar, TextField, Button } from "@mui/material";
import { fetchchatGptData } from "../Apiservices/Auth/servers/chatGptServer";
import Assistant from "./Assistant";

export const Eventspage = () => {
  const location = useLocation();
  const formValues = JSON.parse(sessionStorage.getItem("formValues")) || {};
  // const formValues = state || {};
  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      const apiResponse = await fetchchatGptData(formValues);
      setMessage(apiResponse["answer"]); // Set the mock message
      setLoading(false); // Set loading to false after fetching data
    };
    fetchData();
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "100vh",
        backgroundColor: "#f0f4f8",
        // padding: "20px",
      }}
    >
      {loading ? (
        <Box>
          <Skeleton
            variant="rectangular"
            width={300}
            height={200}
            sx={{ marginBottom: 2 }}
          />
          <Skeleton variant="text" width="60%" />
          <Skeleton variant="text" width="80%" />
          <Skeleton variant="text" width="70%" />
          <Skeleton
            variant="circular"
            width={40}
            height={40}
            sx={{ marginTop: 3 }}
          />
        </Box>
      ) : (
        <div style={{ height: "100%", width: "100%", margin: "30px" }}>
          <Assistant event={formValues.event} message={message} />
        </div>
      )}
    </Box>
  );
};
export default Eventspage;

