import React, { useEffect, useState, useRef } from "react";
import { Button, TextField, Autocomplete } from "@mui/material";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { fetchCategoryEvents } from "../../Apiservices/Auth/servers/fetchCategoryEvents";
import * as Yup from "yup";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import common_colors from "../../styles/common_colors";
import { Calendar } from "primereact/calendar";
import { locale, addLocale } from 'primereact/api';
import config from "../../config";
import { createEvent } from "../../Apiservices/Auth/servers/createEvents";
import { fetchEvent } from "../../Apiservices/Auth/servers/fetchEvents";
import { useSelector } from "react-redux";

const EventForm = ({ onCloseDailog, refreshEvents }) => {
  const navigate = useNavigate();
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const [error, setError] = useState(null);
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [eventData, setEventData] = useState([]);

  const eventLocalStorageKey = config.eventLocalStorageKey;
  const today = new Date();
  const oneYearFromToday = new Date(
    new Date().setFullYear(today.getFullYear() + 1)
  );

  addLocale('in', {
    now: 'OK',
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = "";
        const [events_data] = await Promise.all([fetchCategoryEvents(token)]);
        setEventData(events_data);
        // setFilteredOptions(events_data.map((e) => e.name));
      } catch (error) {
        setError("Failed to fetch data.");
      }
    };
    fetchData();
    let existingData;
    //  existingData = localStorage.getItem(eventLocalStorageKey);
    console.log("existingData", existingData);
    if (existingData) {
      const parsedData = JSON.parse(existingData);

      // Ensure date is set as a Date object
      if (parsedData.Date) {
        parsedData.Date = new Date(parsedData.Date);
      }

      formik.setValues(parsedData); // Set form values with existing data
    }
    locale('in');
  }, []);

  const handleEventInputChange = (event, value) => {
    // Update Formik field value
    handleEventChange("event", value);
    if (value.length >= 3) {
      const newFilteredOptions = eventData
        .map((e) => e.name) // Ensure you're mapping to the right property
        .filter((option) => option.toLowerCase().includes(value.toLowerCase()));

      // console.log("New Filter optoions are", newFilteredOptions);
      // Filter options based on input
      setFilteredOptions(newFilteredOptions); // Update the filtered options
    } else {
      setFilteredOptions([]);
    }
  };

  const alertdialog = () => {
    confirmDialog({
      message: "Please Login to Create Event",
      header: "Login",
      //icon: "pi pi-exclamation-triangle",
      defaultFocus: "accept",
      accept: () => {
        const existingData = localStorage.getItem(eventLocalStorageKey);
        if (existingData) {
          const parsedData = JSON.parse(existingData);
          const updatedValues = { ...parsedData, isPending: true };
          console.log("updatedValues", updatedValues);
          localStorage.setItem(
            eventLocalStorageKey,
            JSON.stringify(updatedValues)
          );
        }
        navigate("/login");
      },
    });
  };

  const formik = useFormik({
    initialValues: {
      Date: "",
      eventTitle: "",
      eventDesc: "",
      //Address: "",
      event: { event: "", id: "" },
    },
    validationSchema: Yup.object({
      event: Yup.object({
        event: Yup.string().required("Event is required"),
      }),
      Date: Yup.string().required("Date is required"),
      //Address: Yup.string().required("Address is required"),
    }),
    onSubmit: async (values) => {
      try {
        if (isLoggedIn) {
          const eventBody = {
            eventTitle: values.eventTitle,
            event: values.event,
            eventDesc: values.eventDesc,
            Date: values.Date && values.Date.toString(),
          };
          try {
            await createEvent(eventBody);
            if (refreshEvents) {
              refreshEvents();
            }
          } catch (error) {
            console.log("Something Went Wrong while Creating Event ");
            console.log(error);
          } finally {
            onCloseDailog();
          }
        } else {
          alertdialog();
        }
        console.log(values);
      } catch (ex) {
        setError(ex.toString());
      }
    },
  });

  // Common method to update local storage
  const updateLocalStorage = (updatedValues) => {
    if (!isLoggedIn) {
      console.log("Is not logged In ", updatedValues);
      localStorage.setItem(eventLocalStorageKey, JSON.stringify(updatedValues));
    } else {
      console.log("Is logged In ");
    }
  };

  const handleFieldChange = (field) => (event) => {
    const value = event.target.value;
    formik.setFieldValue(field, value);
    updateLocalStorage({ ...formik.values, [field]: value });
  };
  //to refresh page after create event
  const handleReload = () => {
    window.location.reload();
  };
  const textFieldStyles = {
    marginBottom: "8px",
    paddingTop: "2px",
    paddingBottom: "2px",
    backgroundColor: "transparent",
    width: "100%",
    zIndex: 1500,
    position: "relative",
  };
  const handleEventChange = (field, value) => {
    const selectedEvent = eventData.find((e) => e.name === value) || {
      id: "",
      name: value,
    };

    // Update Formik
    formik.setFieldValue(field, {
      event: selectedEvent.name,
      id: selectedEvent.id,
    });

    // Update local storage
    updateLocalStorage({
      ...formik.values,
      [field]: {
        event: selectedEvent.name,
        id: selectedEvent.id,
      },
    });
  };

  const calendarRef = useRef(null)

  const closeCalender = () => {
    calendarRef.current.hide()
  };

  const renderAutocomplete = (label, options, field) => (
    <Autocomplete
      options={filteredOptions}
      getOptionLabel={(option) => option}
      freeSolo
      // disablePortal
      value={formik.values[field].event}
      onInputChange={handleEventInputChange}
      onChange={(event, value) => {
        handleEventChange(field, value);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          variant="outlined"
          size="small"
          error={formik.touched.event?.event && Boolean(formik.errors.event?.event)}
          helperText={"Please enter 3 or more letters of the event"}
          sx={textFieldStyles}
          onBlur={() => formik.setFieldTouched(field, true)} // Set touched for the field
        />
      )}
      renderOption={(props, option, { selected }) => (
        <li
          {...props}
          key={option + Math.random()}
          style={{ listStyle: "none" }}
        >
          {option}
        </li>
      )}
    /* PopperProps={{
      style: { zIndex: 1500 }, // This makes the dropdown render above other components
    }} */
    />
  );

  return (
    <div className="event-form">
      <h2>Create Your Event</h2>
      <form onSubmit={formik.handleSubmit}>
        {renderAutocomplete(
          "Select Event",
          eventData.map((e) => e.name),
          "event"
        )}

        <TextField
          name="eventDesc"
          label="Whose event is it?"
          value={formik.values.eventDesc}
          onChange={handleFieldChange("eventDesc")} // Use common change handler
          size="small"
          variant="outlined"
          sx={textFieldStyles}
        />

        <div style={{ marginBottom: '10px' }}>
          <Calendar
            baseZIndex={1100}
            view="date"
            minDate={today}
            maxDate={oneYearFromToday}
            //showButtonBar
            showIcon
            dateFormat="dd-M-yy"
            value={formik.values.Date}
            onChange={(e) => {
              formik.setFieldValue("Date", e.value);
              updateLocalStorage({
                ...formik.values,
                Date: e.value && e.value.toString(),
              });
            }}
            placeholder="Event Date"
            inputStyle={{
              backgroundColor: "transparent",
              width: "100%",
              border:
                formik.touched.Date && formik.errors.Date
                  ? "1px solid red"
                  : "1px solid rgba(0, 0, 0, 0.23)",
            }}
            panelStyle={{ zIndex: 1100 }}
            showTime hourFormat="12"
            ref={calendarRef}
            footerTemplate={(e) => <div className="p-datepicker-buttonbar"><button aria-label="OK" className="p-button-secondary p-button-text p-button p-component" type="button" onClick={closeCalender}><span className="p-button-label p-c">OK</span></button></div>}
          />
          {formik.touched.Date && formik.errors.Date && (<p className="MuiFormHelperText-root Mui-error MuiFormHelperText-sizeSmall MuiFormHelperText-contained">{formik.errors.Date}</p>)}
        </div>

        {/* <TextField
          name="Address"
          label="Address"
          value={formik.values.Address}
          onChange={handleFieldChange("Address")}
          size="small"
          variant="outlined"
          multiline
          rows={2}
          sx={textFieldStyles}
          error={formik.touched.Address && Boolean(formik.errors.Address)}
          helperText={formik.touched.Address && formik.errors.Address}
        /> */}

        {error && <p style={{ color: "red" }}>{error}</p>}

        <Button
          variant="contained"
          // color="primary"
          type="submit"
          sx={{
            width: "100%",
            backgroundColor: "#0cc0df",
            "&:hover": {
              backgroundColor: "#1da3ba",
            },
          }}
        >
          Create My Event
        </Button>
      </form>
      <ConfirmDialog />
    </div>
  );
};

export default EventForm;
