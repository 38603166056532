import React from 'react';
import { useNavigate } from 'react-router-dom';
import { AppBar, Toolbar, Typography, InputBase, IconButton } from '@mui/material';
//import SearchIcon from '@mui/icons-material/Search';
import SocialMediaIcons from './SocialMediaIcons'; // A custom component for social media icons

//import Stack from '@mui/material/Stack';
import MenuIcon from '@mui/icons-material/Menu';
import { ThemeProvider, createTheme } from '@mui/material/styles';
const MyAppBar = () => {
 const navigate =useNavigate();
 const loginpag=()=>{
  navigate('/login')
 }
  return (
    <AppBar position="fixed"  color="primary" enableColorOnDark>
      <Toolbar>
        <Typography variant="h6" style={{ flexGrow: 1 }}>
          {/* Company Name */}
        </Typography>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <span onClick={loginpag} style={{cursor:'pointer'}}>Log-in / Sign-Up </span>
          {/* <InputBase placeholder="Search Events" style={{ marginRight: '1rem' ,
              color:"grey",
              backgroundColor: 'white',
              borderRadius: 1,}} />
          <SearchIcon /> */}
        </div>
        <SocialMediaIcons />
      </Toolbar>
    </AppBar>
  );
};


function appBarLabel(label) {
  return (
    <Toolbar>
      <IconButton edge="start" color="inherit" aria-label="menu" sx={{ mr: 2 }}>
        <MenuIcon />
      </IconButton>
      <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1 }}>
        {label}
      </Typography>
    </Toolbar>
  );
}

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#1976d2',
    },
  },
});



export default MyAppBar;
