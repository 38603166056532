import React from "react";
import {
    Box,
    Paper
} from "@mui/material";
import './ServiceProviderDashboard.css';
import ManageProfileBasicInfo from "./ManageProfileBasicInfo";
import ManageProfileAddressInfo from "./ManageProfileAddressInfo";

const ManageProfileTabContent = () => {

    return (
        <div className="user-profile">
            <Paper className="profile-card">
                {/* Basic Info */}
                <ManageProfileBasicInfo />
            </Paper>

            <Paper className="profile-card">
                {/* Address Info */}
                <ManageProfileAddressInfo />
            </Paper>
        </div>
    )
}
export default ManageProfileTabContent;