// src/components/OTP.js
import React, {useState} from 'react';
import styled from 'styled-components';
import { useFormik } from 'formik';
import { useLocation ,useNavigate} from 'react-router-dom';
import { verifyotp } from '../Apiservices/Auth/servers/verifyOtp';
import { useDispatch, useSelector } from 'react-redux';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #f7f7f7;
  padding: 20px;
`;
const BgContainer=styled.div`
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 100vh; 
  display: flex;
  justify-content: center;
  align-items: center;

`

const OTPForm = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 400px;
  padding: 20px;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 5px;

  @media (max-width: 768px) {
    padding: 15px;
  }
`;

const Title = styled.h1`
  text-align: center;
  margin-bottom: 20px;
  color: #333;
`;

const OTPInputContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
`;

const OTPInput = styled.input`
  width: 40px;
  height: 40px;
  text-align: center;
  border: 1px solid #ddd;
  border-radius: 3px;
  font-size: 18px;

  @media (max-width: 768px) {
    width: 35px;
    height: 35px;
  }
`;

const Button = styled.button`
  padding: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 3px;
  font-size: 16px;
  cursor: pointer;

  &:hover {
    background-color: #0056b3;
  }
`;

const OTP = () => {
  const location = useLocation();
  const { identifier } = location.state || {}; 
  const dispatch= useDispatch();
  const verifyauth=useSelector(state=>state);
  const[msgerror,setmsgError]=useState('');
  const {loading,user,error}=verifyauth;
  const navigate = useNavigate();
 const [otpVerifier,setOtpVerifier]=useState({'identifier':identifier,'otpCode':''});
  const formik = useFormik({
    initialValues: {
      otp1: '',
      otp2: '',
      otp3: '',
      otp4: '',
      otp5: '',
      otp6: '',
    },
    onSubmit: values => {
      const otp = Object.values(values).join('');
      console.log('opt2:',otp);
     if(otp==='' || otp===null){
      setmsgError('Please enter valid OTP')
     }else{
      setmsgError('');
      dispatch(verifyotp(otpVerifier))
        navigate('/dashboard');
     }
     // alert(`OTP Entered: ${otp}`);
    },
  });

  const handleInputChange = (e, index) => {
    const {name,value } = e.target;
    if (/^[0-9]$/.test(value) || value === '') {
      formik.setFieldValue(`otp${index + 1}`, value);
      const updatedOtpVerifier = { ...otpVerifier, [name]: value };
      // Generate the otpCode by joining the values of the individual otp fields
      const otpCode = Object.keys(updatedOtpVerifier)
          .filter((key) => key.startsWith('otp'))
          .map((key) => updatedOtpVerifier[key])
          .join('');

      // Set the full OTP code in the otpVerifier state
      setOtpVerifier({
          ...otpVerifier,
          otpCode,
      });
      if (value !== '' && index < 5) {
        document.getElementById(`otp${index + 2}`).focus();
      }
    } else if (value === '') {
      formik.setFieldValue(`otp${index + 1}`, '');
    }
  };

  return (
    <BgContainer>
      <Container>
      <OTPForm onSubmit={formik.handleSubmit}>
        <Title>Enter OTP</Title>
        {msgerror && <div style={{color:"red",paddingBottom:"5px"}}>{msgerror}</div>}
        {error && <div style={{color:"red",paddingBottom:"5px"}}>{error}</div>}
        {user && <div style={{color:"green",paddingBottom:"5px"}}>{user.message}</div>}
        <OTPInputContainer>
          {[0, 1, 2, 3, 4, 5].map(index => (
            <OTPInput
              key={index}
              id={`otp${index + 1}`}
              name={`otp${index + 1}`}
              type="text"
              maxLength="1"
              onChange={e => handleInputChange(e, index)}
              value={formik.values[`otp${index + 1}`]}
            />
          ))}
        </OTPInputContainer>
        <Button type="submit">{loading?'Verifying..':'Verify OTP'}</Button>
      </OTPForm>
    </Container>
    </BgContainer>
    
  );
};

export default OTP;
