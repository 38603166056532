import React from 'react';
import MiddleComponent from './MiddleComponent'
import config from '../config';

function Footer() {
  const year = new Date();
  return (
    <footer>
      <MiddleComponent></MiddleComponent>
      <div className='copyright'>© {year.getFullYear()} {config.companyName}. All rights reserved.</div>
    </footer>
  );
}
export default Footer;
