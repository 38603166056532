import config from "../../../config";

export const fetchchatGptData = async (params) => {
  console.log("body:", JSON.stringify(params));
  try {
    const response = await fetch(`${config.API_BASE_URL}/chat/generate-question`, {
      method: "POST",
      headers: { "Content-type": "application/json" },
      body: JSON.stringify(params)
    });
    const answer = await response.json();
    if (response.ok) {
      return answer;
    } else {
      throw new Error(answer.toString(), "something went wrong")
    }
  } catch (ex) {
    console.log("chat gpt error:", ex.to)
  }
}

export const fetchchatGptHowTo = async (params) => {
  console.log("body:", JSON.stringify(params));
  try {
    const response = await fetch(`${config.API_BASE_URL}/chat/generate`, {
      method: "POST",
      headers: { "Content-type": "application/json" },
      body: JSON.stringify(params)
    });
    const answer = await response.json();
    if (response.ok) {
      return answer;
    } else {
      throw new Error(answer.toString(), "something went wrong")
    }
  } catch (ex) {
    console.log("chat gpt error:", ex.to)
  }
}