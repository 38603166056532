// src/utils/authUtils.js
import { decodeToken } from "react-jwt";

export const isTokenExpired = (token) => {
  if (token === null || token === undefined) return true;
  console.log(token);
  const decodedToken = decodeToken(token);
  if (decodedToken === null || decodedToken === undefined) return true;
  const isExpired = decodedToken.exp * 1000 < Date.now();
  
  return isExpired; // Compare expiration time with current time
};


export const getRoleFromToken = (token) => {
  const decodedToken = decodeToken(token);
  return decodedToken?.userType || null;
};
