import React from "react";
import { Button, Grid, Typography, Box } from "@mui/material";
import "./Assistant.css";
import RollingPaperText from "./rollingPaper/RollingPaperText";
const inviteIconPath = "/assets/images/inviteIcon.png";
const assistantPicPath = "/assets/images/assistant.png";
const logoPicPath = "/assets/images/thurent.png";
const Assistant = ({ event = "", message = "" }) => {
  //   const event = "Wedding";
  //   const message =
  //     "Namakarana, also known as the Naming Ceremony, is an important ritual in Hinduism that is performed to give a newborn baby its name. In the Telugu-speaking Brahmin community, this ceremony holds great significance and is usually conducted on the 11th or 21st day after the birth of the child...\n\n(continue with the rest of your message)";

  return (
    <Box
      sx={{ padding: "20px", height: "100%", width: "100%" }}
      className="assistant_containter"
    >
      <Grid container spacing={2}>
        {/* First Column - Image */}
        <Grid item xs={12} md={4} lg={3} className="assistant__column">
          <Box
            component="img"
            src={assistantPicPath}
            alt="Amma Pic"
            className="assistant__image"
          />
        </Grid>

        {/* Second Column - Text */}
        <Grid item xs={12} md={8} lg={6} className="assistant__column">
          <Typography variant="h5" gutterBottom>
            Just as Grandma used to say, here are the essential steps followed
            for {event}.
          </Typography>
          <RollingPaperText myText={message}></RollingPaperText>
        </Grid>

        {/* Third Column - Image and Button */}
        <Grid item xs={12} md={12} lg={3} className="assistant__column">
          <div className="header">
            WANT TO INVITE <br />
            FAMILY/FRIENDS <br />
            ON WHATSAPP?
          </div>
          <Box
            component="img"
            src={inviteIconPath}
            alt="Image 2"
            className="assistant__image"
          />
          <Button
            variant="text"
            color="primary"
            sx={{
              marginTop: "10px",
              marginRight: "60px",
              fontSize: "25px", // Increases the font size of the button text
              padding: "12px 24px", // Increases button padding
              alignSelf: "center", // Aligns button to the right
            }}
          >
            Click Here
          </Button>
          <div style={{ display: "flex", alignItems: "flex-end" }}>
            <Box 
            style={{width:"40%" ,maxWidth:"200px"}}
              component="img"
              src={logoPicPath}
              alt="logo"
              className="assistant__image"
            />
            <span style={{marginBottom:"20px"}}>Can Help You.</span>
          </div>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Assistant;
