import React from "react";
import { GoogleMap, MarkerF, useLoadScript } from "@react-google-maps/api";

const LoadMap = (props) => {

    const googleMapsApiKey = process.env.REACT_APP_GOOGLE_API_KEY;

    const containerStyle = {
        width: props.width,
        height: props.height
    };

    const { isLoaded } = useLoadScript({
        googleMapsApiKey: googleMapsApiKey, // Replace with your actual API key
    });

    const newPosition = {
        lat: parseFloat(props.locationLat),
        lng: parseFloat(props.locationLong),
    };

    return (
        <div className="event-service-map">
            {isLoaded && (
                <GoogleMap
                    mapContainerStyle={containerStyle}
                    zoom={15}
                    center={newPosition}
                >
                    <MarkerF
                        position={newPosition}
                    />
                </GoogleMap>
            )}
        </div>
    )
}

export default LoadMap