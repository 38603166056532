import React, { useRef, useState } from "react";
import "../css/ForgotPassword.css";
import styled from "styled-components";
import {
  requestResetPassword,
  resetPasswordViaOtp,
} from "../services/AuthServices"; // Import the new service function
import { useFormik } from "formik";
import * as Yup from "yup"; // for validation
import { useNavigate } from "react-router-dom";
import { Toast } from "primereact/toast";

const ServiceProviderForgotPassword = () => {
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [otpSent, setOtpSent] = useState(false); // To control OTP and password fields visibility
  const navigate = useNavigate();
  const toast = useRef(null);
  const formik = useFormik({
    initialValues: {
      identifier: "",
      otp: "", // Single input for OTP
      newPassword: "",
      confirmPassword: "",
    },
    validationSchema: Yup.object({
      identifier: Yup.string()
        .required("Please enter a valid email or mobile number")
        .matches(
          /^[^\s@]+@[^\s@]+\.[^\s@]+$|^[0-9]{10}$/,
          "Invalid email or 10-digit mobile number"
        ),
      otp: otpSent
        ? Yup.string()
            .length(6, "OTP must be exactly 6 digits")
            .required("OTP is required")
        : null,
      newPassword: otpSent
        ? Yup.string()
            .min(8, "Password must be at least 8 characters")
            .required("New password is required")
        : null,
      confirmPassword: otpSent
        ? Yup.string()
            .oneOf([Yup.ref("newPassword"), null], "Passwords must match")
            .required("Please confirm your new password")
        : null,
    }),
    onSubmit: async (values) => {
      setLoading(true);
      try {
        if (!otpSent) {
          // Handle OTP sending logic
          const responseMessage = await requestResetPassword(values.identifier);
          setMessage(responseMessage);
          setOtpSent(true); // Move to OTP entry step
        } else {
          // Handle OTP verification and password reset
          const responseMessage = await resetPasswordViaOtp({
            identifier: values.identifier,
            otpCode: values.otp,
            newPassword: values.newPassword,
          });
          setMessage(responseMessage);
          toast.current.show({
            severity: "success",
            summary: "Success",
            detail: responseMessage,
            life: 3000,
          });
          setTimeout(() => {
            navigate("/service-provider/login");
          }, 3000); // Adjust time (3000 ms = 3 seconds) as needed
        }
      } catch (err) {
        setMessage(err.message); // Set the error message from the service
      } finally {
        setLoading(false);
      }
    },
  });

  return (
    <div className="auth-screens-bg">
      <Toast ref={toast} />
      <div className="forgot-password-card">
        <img
          src={`${process.env.PUBLIC_URL}/assets/images/thurent.png`}
          alt="Logo"
          className="auth-logo"
          onClick={() => {
            navigate("/");
          }}
        />
        <form onSubmit={formik.handleSubmit}>
          <input
            type="text"
            name="identifier"
            placeholder="Enter email or mobile number"
            value={formik.values.identifier}
            onChange={formik.handleChange}
            disabled={otpSent} // Disable after OTP is sent
            className="forgot-password-input"
          />
          {formik.errors.identifier && formik.touched.identifier && (
            <p className="error">{formik.errors.identifier}</p>
          )}

          {otpSent && (
            <>
              <input
                type="text"
                name="otp"
                placeholder="Enter 6-digit OTP"
                value={formik.values.otp}
                onChange={formik.handleChange}
                maxLength="6" // Limit OTP input to 6 digits
                className="forgot-password-input"
              />
              {formik.errors.otp && formik.touched.otp && (
                <p className="error">{formik.errors.otp}</p>
              )}

              <input
                type="password"
                name="newPassword"
                placeholder="Enter new password"
                value={formik.values.newPassword}
                onChange={formik.handleChange}
                className="forgot-password-input"
              />
              {formik.errors.newPassword && formik.touched.newPassword && (
                <p className="error">{formik.errors.newPassword}</p>
              )}

              <input
                type="password"
                name="confirmPassword"
                placeholder="Confirm new password"
                value={formik.values.confirmPassword}
                onChange={formik.handleChange}
                className="forgot-password-input"
              />
              {formik.errors.confirmPassword &&
                formik.touched.confirmPassword && (
                  <p className="error">{formik.errors.confirmPassword}</p>
                )}
            </>
          )}

          {message && (
            <p
              className="message"
              style={{
                color: message.includes("successfully") ? "green" : "red",
              }}
            >
              {message}
            </p>
          )}

          <button
            type="submit"
            className="forgot-password-button"
            disabled={loading}
          >
            {loading
              ? otpSent
                ? "Verifying OTP..."
                : "Sending OTP..."
              : otpSent
              ? "Reset Password"
              : "Send OTP"}
          </button>
        </form>
      </div>
    </div>
  );
};

export default ServiceProviderForgotPassword;
