import config from "../../../config";
import axiosInstance from '../../../util/axiosInstance';

import { getToken } from "../../../util/Cookies";

export const fetchProviderData = async () => {
    try {
        const response = await axiosInstance.get('/provider-types', {
            method: "GET",
        })
        if (response.status === 200) {
            return response.data;
        } else {
            throw new Error('Network response was not ok');
        }
    } catch (error) {
        console.error('There was a problem with the fetch operation:', error);
    }
};

export const getServiceProviderServices = async () => {
    const token = getToken();

    try {
        const response = await axiosInstance.get('/services', {
            method: "GET",
            headers: {
                Authorization: `${token}`,
            },
        })
        if (response.status === 200) {
            return response.data;
        } else {
            throw new Error(response.message || 'Unable to get the provider details');
        }
    } catch (error) {
        // dispatch(profileFailuer(error.message));
        console.error('There was a problem with the fetch operation:', error);
    }
};

export const getServiceProvidersByID = async (Id,Lat,Long) => {
    try {
        const response = await axiosInstance.get(`/provider?typeId=${Id}&latitude=${Lat}&longitude=${Long}&order=asc`, {
            method: "GET",
        })
        if (response.status === 200) {
            return response.data;
        } else {
            throw new Error(response.message || 'Unable to get the provider details');
        }
    } catch (error) {
        // dispatch(profileFailuer(error.message));
        console.error('There was a problem with the fetch operation:', error);
    }
};

export const getProviderServicesByID = async (Id) => {
    try {
        const response = await axiosInstance.get(`/provider/services?providerId=${Id}`, {
            method: "GET",
        })
        if (response.status === 200) {
            return response.data;
        } else {
            throw new Error(response.message || 'Unable to get the provider details');
        }
    } catch (error) {
        // dispatch(profileFailuer(error.message));
        console.error('There was a problem with the fetch operation:', error);
    }
};
