import React, { useCallback, useState, useEffect } from "react";
import { useFormik } from "formik"; // Formik import
import * as Yup from "yup"; // Yup for validation
import useWindowSize from "../../util/useWindowSize";
import { Box, TextField, Button } from "@mui/material";
import { Dialog } from "primereact/dialog";
import EditTwoToneIcon from "@mui/icons-material/EditTwoTone";
import LocationSearchingTwoToneIcon from "@mui/icons-material/LocationSearchingTwoTone";
import AddTwoToneIcon from "@mui/icons-material/AddTwoTone";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import "./ServiceProviderDashboard.css";
import { getServiceProviderProfile } from "../../Apiservices/Auth/servers/profileServer";
import {
  addServiceProviderAddressInfo,
  editServiceProviderAddressInfo,
} from "../../Apiservices/Auth/servers/updateServiceProviderProfile";
import { GoogleMap, useLoadScript, MarkerF } from "@react-google-maps/api";

const ManageProfileAddressInfo = () => {
  const width = useWindowSize();

  const googleMapsApiKey = process.env.REACT_APP_GOOGLE_API_KEY;

  const [providerAddressInfo, setProviderAddressInfo] = useState([]);
  const [loading, setLoading] = useState(true);
  const [address, setAddress] = useState({});
  const [formattedAddress, setFormattedAddress] = useState(null);
  const [isDisabled, setIsDisabled] = useState(true);
  const [isDialogVisible, setIsDialogVisible] = useState(false);
  const [center, setCenter] = useState({ lat: 0, lng: 0 });
  const [markerPosition, setMarkerPosition] = useState({ lat: 0, lng: 0 });

  useEffect(() => {
    console.log("Provider Address Info:", providerAddressInfo);
    console.log("Center:", center);
    console.log("Marker Position:", markerPosition);
  }, [providerAddressInfo, center, markerPosition]);

  const isValidLatLng = (lat, lng) =>
    typeof lat === "number" &&
    !isNaN(lat) &&
    typeof lng === "number" &&
    !isNaN(lng);
  // Get current location using Geolocation API
  const getCurrentLocation = () => {
    console.log("Get current Location is called");
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          console.log(position.coords);
          console.log("Latitude , lognttude");
          if (isValidLatLng(latitude, longitude)) {
            const newPosition = { lat: latitude, lng: longitude };
            setCenter(newPosition);
            setMarkerPosition(newPosition);
          }
        },
        (error) => {
          console.error("Error getting location:", error);
        }
      );
    } else {
      console.error("Geolocation not supported by this browser.");
    }
  };

  const fetchProviderData = useCallback(async () => {
    setLoading(true);
    try {
      const providerData = await getServiceProviderProfile();
      setProviderAddressInfo(
        Object.keys(providerData).length > 0 ? providerData.addresses : []
      );

      //   if (Object.keys(providerData).length > 0) {
      //     const newPosition = {
      //       lat: providerData.addresses[0].latitude,
      //       lng: providerData.addresses[0].longitude,
      //     };
      //     setAddress(providerData.addresses[0]);
      //     setCenter(newPosition);
      //     setMarkerPosition(newPosition);
      //   }
      if (providerData.addresses && providerData.addresses.length > 0) {
        const newPosition = {
          lat: providerData.addresses[0].latitude,
          lng: providerData.addresses[0].longitude,
        };
        setAddress(providerData.addresses[0]);
        setCenter(newPosition);
        setMarkerPosition(newPosition);
      }
    } catch (error) {
      console.error("Failed to fetch provider:", error);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchProviderData();
  }, [fetchProviderData]);

  useEffect(() => {
    providerAddressInfo.length === 0 && getCurrentLocation();
  }, []);

  const openDialog = () => {
    fetchProviderData();
    setIsDialogVisible(true);
  };

  const closeDialog = () => {
    fetchProviderData();
    setIsDialogVisible(false);
  };

  const getAddressObject = (address_components) => {
    var ShouldBeComponent = {
      streetNumber: ["premise", "street_number"],
      pincode: ["postal_code"],
      locality: ["neighborhood", "locality", "sublocality"],
      street: ["route", "sublocality_level_1", "sublocality_level_2"],
      state: [
        "administrative_area_level_1",
        "administrative_area_level_2",
        "administrative_area_level_4",
        "administrative_area_level_5",
      ],
      city: [
        "locality",
        "sublocality_level_1",
        "sublocality_level_3",
        "sublocality_level_4",
        "administrative_area_level_3",
      ],
      country: ["country"],
    };

    const address = {
      streetNumber: "",
      pincode: "",
      locality: "",
      street: "",
      state: "",
      city: "",
      country: "",
    };

    address_components.forEach((component) => {
      for (var shouldBe in ShouldBeComponent) {
        if (ShouldBeComponent[shouldBe].indexOf(component.types[0]) !== -1) {
          address[shouldBe] = component.long_name;
        }
      }
    });
    return address;
  };

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: googleMapsApiKey, // Replace with your actual API key
  });

  const handleMarkerDragEnd = (event) => {
    if (event && event.latLng) {
      const newPosition = {
        lat: parseFloat(event.latLng.lat()),
        lng: parseFloat(event.latLng.lng()),
      };
      setMarkerPosition(newPosition);
      const geocoder = new window.google.maps.Geocoder();
      geocoder.geocode({ location: newPosition }, (results, status) => {
        if (status === "OK" && results[0]) {
          console.log("Results", results[0]);
          setAddress(getAddressObject(results[0].address_components));
          setFormattedAddress(results[0].formatted_address);
        } else {
          console.error("Geocoder failed due to: " + status);
        }
      });
    }
  };

  const formikAddressInfo = useFormik({
    enableReinitialize: true,
    initialValues: {
      streetNumber: address.streetNumber,
      street: address.street,
      locality: address.locality,
      pincode: address.pincode,
      city: address.city,
      state: address.state,
      country: address.country,
      latitude: markerPosition.lat,
      longitude: markerPosition.lng,
      formattedAddress: formattedAddress
        ? formattedAddress
        : providerAddressInfo.length > 0
          ? providerAddressInfo[0]?.formattedAddress
          : "",
      optionalNotes: address.optionalNotes,
    },
    validationSchema: Yup.object({
      formattedAddress: Yup.string().required("Address is required"),
    }),
    onSubmit: async (values) => {
      const addressValues = JSON.stringify(values);
      //console.log(addressValues)
      setLoading(true);
      try {
        const response =
          providerAddressInfo.length > 0
            ? await editServiceProviderAddressInfo(
              addressValues,
              providerAddressInfo[0].id
            )
            : await addServiceProviderAddressInfo(addressValues);
      } catch (error) {
        console.error("Failed to services:", error);
      } finally {
        fetchProviderData();
        closeDialog();
        setLoading(false);
      }
    },
  });

  const handleBasicFieldChange = (field) => (event) => {
    const value = event.target.value;
    formikAddressInfo.setFieldValue(field, value);
  };

  const textFieldStyles = {
    backgroundColor: "#ffffff",
    width: "100%",
    borderRadius: "4px",
    marginBottom: "10px",
  };
  const mapContainerStyle = {
    height: "70vh",
    width: "100%",
  };

  return (
    <Box className="profile-card-panel">
      <Box className="profile-card-panel-header">
        <label>Address</label>
      </Box>
      <Box className="profile-card-panel-body">
        <div className="profile-item">
          <div
            style={{
              width: "100%",
              display: "flex",
              alignItems: "flex-start",
              justifyContent: "flex-start",
              gap: "20px",
            }}
          >
            <div className="picon loc">
              <LocationOnOutlinedIcon />
            </div>
            <div
              style={{
                width: "calc(100% - 70px)",
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                gap: "10px",
              }}
            >
              <h2
                style={{
                  margin: "0",
                  fontSize: "18px",
                  lineHeight: "25px",
                  fontWeight: "500",
                }}
              >
                {providerAddressInfo.length > 0
                  ? providerAddressInfo[0].formattedAddress
                  : "No address found, please add your address"}
              </h2>
              <div>
                <Button
                  onClick={openDialog}
                  size="small"
                  variant="outlined"
                  startIcon={
                    providerAddressInfo.length > 0 ? (
                      <EditTwoToneIcon />
                    ) : (
                      <AddTwoToneIcon />
                    )
                  }
                  sx={{
                    color: "#0cc0df",
                    borderColor: "#0cc0df",
                  }}
                >
                  {providerAddressInfo.length > 0 ? "Edit" : "Add"} Address
                </Button>
              </div>
              <Dialog
                className="add-address"
                header={
                  <Button
                    size="small"
                    variant="outlined"
                    startIcon={<LocationSearchingTwoToneIcon />}
                    sx={{
                      color: "#0cc0df",
                      borderColor: "#0cc0df",
                    }}
                  >
                    Select on map
                  </Button>
                }
                visible={isDialogVisible}
                onHide={closeDialog}
              >
                <div className="p-fluid">
                  <div id="AddressInfoForm" className="p-fluid">
                    <Box>
                      {isLoaded && center.lat !== 0 && center.lng !== 0 ? (
                        <GoogleMap
                          className="map-container"
                          mapContainerStyle={mapContainerStyle}
                          center={center}
                          zoom={18}
                        >
                          <MarkerF
                            position={markerPosition}
                            draggable={true}
                            onDragEnd={handleMarkerDragEnd}
                          />
                        </GoogleMap>
                      ) : (
                        <div>Loading...</div>
                      )}
                    </Box>
                    <Box>
                      <form
                        style={{ width: "100%" }}
                        onSubmit={formikAddressInfo.handleSubmit}
                      >
                        <TextField
                          type="hidden"
                          name="streetNumber"
                          value={formikAddressInfo.values.streetNumber}
                        />
                        <TextField
                          type="hidden"
                          name="street"
                          value={formikAddressInfo.values.street}
                        />
                        <TextField
                          type="hidden"
                          name="locality"
                          value={formikAddressInfo.values.locality}
                        />
                        <TextField
                          type="hidden"
                          name="pincode"
                          value={formikAddressInfo.values.pincode}
                        />
                        <TextField
                          type="hidden"
                          name="city"
                          value={formikAddressInfo.values.city}
                        />
                        <TextField
                          type="hidden"
                          name="state"
                          value={formikAddressInfo.values.state}
                        />
                        <TextField
                          type="hidden"
                          name="country"
                          value={formikAddressInfo.values.country}
                        />
                        <TextField
                          type="hidden"
                          name="latitude"
                          value={formikAddressInfo.values.latitude}
                        />
                        <TextField
                          type="hidden"
                          name="longitude"
                          value={formikAddressInfo.values.longitude}
                        />
                        <TextField
                          disabled
                          name="formattedAddress"
                          label="Address"
                          multiline
                          maxRows={6}
                          value={formikAddressInfo.values.formattedAddress}
                          onChange={handleBasicFieldChange("formattedAddress")} // Use common change handler
                          variant="outlined"
                          sx={textFieldStyles}
                          error={
                            formikAddressInfo.touched.formattedAddress &&
                            Boolean(formikAddressInfo.errors.formattedAddress)
                          }
                          helperText={
                            formikAddressInfo.touched.formattedAddress &&
                            formikAddressInfo.errors.formattedAddress
                          }
                        />
                        <TextField
                          name="optionalNotes"
                          label="Give more Details"
                          multiline
                          maxRows={6}
                          value={formikAddressInfo.values.optionalNotes}
                          onChange={handleBasicFieldChange("optionalNotes")} // Use common change handler
                          variant="outlined"
                          sx={textFieldStyles}
                          InputLabelProps={{ shrink: true }}
                          placeholder="ex: nearest landmark, floor number, or any other detail that helps to identify your location without calling you"
                        />
                        <Button
                          type="submit"
                          sx={{
                            width: "100%",
                            height: "40px",
                            color: "#ffffff",
                            backgroundColor: "#0cc0df",
                            "&:hover": {
                              backgroundColor: "#1da3ba",
                            },
                          }}
                        >
                          Confirm &{" "}
                          {providerAddressInfo.length > 0 ? "Update" : "Add"}{" "}
                          Address
                        </Button>
                      </form>
                    </Box>
                  </div>
                </div>
              </Dialog>
            </div>
          </div>
        </div>
      </Box>
    </Box>
  );
};
export default ManageProfileAddressInfo;
