import React, { useCallback, useEffect, useState } from "react";
import {
    CssBaseline,
    Stack,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import Footer from "./Footer";
import ResponsiveAppBar from "./appBarcomponent/ResponsiveAppbar";
import "./eventComponent/EventTabs/EventTabs.css"
import { Button } from "primereact/button";
import { fetchProviderData } from "../Apiservices/Auth/servers/fetchProviderData";


function Celebrations() {
    const [loading, setLoading] = useState(true);
    const [providerData, setProviderData] = useState([]);

    const navigate = useNavigate();

    const backtoEvents = () => {
        navigate("/");
    }

    const fetchProviders = useCallback(async () => {
        setLoading(true);
        try {
            const provider_data = await fetchProviderData();
            setProviderData(provider_data);
        } catch (error) {
            console.error("Failed to fetch events:", error);
        } finally {
            setLoading(false);
        }
    }, []);

    useEffect(() => {
        fetchProviders();
    }, []);

    const handleEventService = (service) => {
        const info = { serviceDetails: service }
        navigate("/service", { state: info });
    }

    return (
        <div style={{ width: "100%" }}>
            <CssBaseline />
            <Stack spacing={7} sx={{ flexGrow: 1 }}>
                <ResponsiveAppBar />
            </Stack>
            <div className="main-container">
                <div className="frontend-container">
                    <div style={{ width: '100%', paddingBottom: '30px' }}>
                        <div className="event-form-inner">
                            <div className="event-form-head">
                                <div className="event-service-head-inner">
                                    <div>
                                        <h3>Celebrations</h3>
                                        <div className="event-rsvp">
                                        Invite and manage your guests.
                                        </div>
                                    </div>
                                </div>
                                <div className="btns">
                                    {/* <Button
                                        icon="pi pi-angle-left"
                                        className="custom-button"
                                        label="Go Back"
                                        onClick={backtoEvents}
                                    /> */}
                                </div>
                            </div>
                            <div className="event-form-body list">
                                <div>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean quis nunc quis nisi ultrices rhoncus quis varius justo. Quisque vel neque a elit iaculis egestas. Cras ac pellentesque sem, a vestibulum sapien. Duis quis malesuada tortor. Mauris interdum facilisis dui in interdum. Phasellus tempus aliquam odio quis eleifend. Curabitur at finibus magna. Praesent finibus orci massa, id scelerisque massa porta vel. Aliquam sit amet magna a elit commodo commodo et facilisis odio.</p>

                                    <p>Morbi arcu justo, ultrices a lorem quis, porta scelerisque lorem. Nulla mattis odio id urna posuere tincidunt. Phasellus tristique ultricies mi et ornare. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Aliquam eget ligula a leo rutrum maximus at vel odio. Nam efficitur aliquet tellus. Aliquam non nunc justo. Morbi aliquet arcu quis erat pulvinar, non auctor tortor pulvinar. In hac habitasse platea dictumst. Suspendisse maximus condimentum dui sed scelerisque. Phasellus lorem felis, ullamcorper at sapien non, ultricies luctus ipsum. Cras egestas rutrum enim a commodo.</p>
                                </div>
                                <div className="event-service-list">
                                    {providerData.length > 0 && providerData.map((item) => <div key={item.id} className="event-service" onClick={() => handleEventService(item)}>
                                        <div className="event-service-head">
                                            <div>
                                                <div>
                                                    <h3>{item.serviceName}</h3>
                                                    <div className="event-rsvp">
                                                        {item.serviceDescription}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="pi pi-chevron-right"></div>
                                        </div>
                                    </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Celebrations