import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import {
  fetchRSVPDetails,
  submitRSVPResponse,
} from "../../services/RSVPServices";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Toast } from "primereact/toast";
import "./RSVPInvitationPage.css";
import formatDate from "../../util/FormateDate";

export const RSVPInvitationPage = () => {
  const { sharedToken } = useParams();
  const [eventDetails, setEventDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [submittedResponse, setSubmittedResponse] = useState(null);
  const toast = useRef(null);
  // Utility function to show success toast
  const showSuccess = (details) => {
    toast.current.show({
      severity: "success",
      summary: "Response Submitted",
      detail: details,
      life: 3000,
    });
  };

  // Utility function to show error toast
  const showError = (detail) => {

    toast.current.show({
      severity: "error",
      summary: "Submission Failed",
      detail: detail,
      life: 3000,
    });
  };
  useEffect(() => {
    // Check if a response is already saved in local storage
    const savedResponse = localStorage.getItem(`rsvp_${sharedToken}`);
    try {
      if (savedResponse) {
        console.log("savedResponse => ", savedResponse);
        setSubmittedResponse(JSON.parse(savedResponse));
      } else {
        fetchEventDetails();
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  }, [sharedToken]);

  const fetchEventDetails = async () => {
    try {
      const data = await fetchRSVPDetails(sharedToken);
      if (data.message === "Event not found or invalid token") {
        setError("Sorry, no active event found");
      } else {
        setEventDetails(data);
      }
    } catch (err) {
      setError("Sorry, no active event found");
    }
  };

  console.log("eventDetails", eventDetails)

  const RSVPValidationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    mobile: Yup.string()
      .matches(/^[6-9]\d{9}$/, "Enter a valid mobile number")
      .required("Mobile number is required"),
    attendance: Yup.string().required("Please select your attendance"),
    members: Yup.number()
      .min(1, "Must be at least 1 member")
      .required("Number of members is required"),
  });

  const handleSubmit = async (values, { setSubmitting, setErrors }) => {
    const isComing = values.attendance === "yes";
    console.log("values => ", values);
    const rsvpPayload = {
      name: values.name,
      phoneNumber: values.mobile,
      sharedToken: sharedToken,
      isComing: isComing,
      isReminderRequired: values.remind,
      //   extraMembers: values.members > 1 ? values.members - 1 : 0, // Correctly calculate extra members
      extraMembers: isComing ? values.members : 0, // Correctly calculate extra members
      remarks: values.suggestion,
    };
    console.log("rsvpPayload => ", rsvpPayload);

    try {
      const response = await submitRSVPResponse(rsvpPayload);
      console.log(response);
      if (response.status === 200 || response.status === 201) {
        const rsvpData = {
          ...response.data.rsvp,
          submitted: true,
          statusCode: response.status,
        };
        localStorage.setItem(`rsvp_${sharedToken}`, JSON.stringify(rsvpData));
        setSubmittedResponse(rsvpData);
        showSuccess(`Thank you for your response, ${rsvpData.name}!`);

      } else if (response.status === 409) {
        const conflictResponse = {
          submitted: true,
          statusCode: response.status,
        };
        localStorage.setItem(
          `rsvp_${sharedToken}`,
          JSON.stringify(conflictResponse)
        );
        setSubmittedResponse(conflictResponse);
        showError("Response already submitted .");
      }
    } catch (error) {
      console.error(error);
      showError("Something went wrong. Please try again later.");
    } finally {
      setSubmitting(false);
    }
  };

  if (loading) {
    return <>
      <Toast ref={toast} />
      <div>Loading...</div>;
    </>
  }

  //   if (error) {
  //     return <div>{error}</div>;
  //   }

  if (submittedResponse) {
    return (
      <div
        style={{
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Toast ref={toast} />
        <div className="thank-you-card">
          <h2>
            Thank you for your response
            {submittedResponse && submittedResponse.name ? `, ${submittedResponse.name}!` : ""}
          </h2>
        </div>
      </div>
    );
  }
  if (!eventDetails || error) {
    return (
      <div
        style={{
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Toast ref={toast} />
        <div className="thank-you-card">
          <h2>{!eventDetails ? "No event details available" : error}</h2>
        </div>
      </div>
    );
  }

  const { eventType, invitedBy, venue, startDate } = eventDetails;

  return (
    <div className="rsvp-invitation-container">
      <Toast ref={toast} />
      <div className="rsvp-invitation-inner">
        <img style={{width:'70px',margin:'0 auto'}} src={`${process.env.PUBLIC_URL}/assets/images/invgod.png`} />
        <div className="rsvp-invitation-card">
          {eventType && (
            <div className="rsvp-invitation-head">
              <div>You are invited to</div>
              <div className="event-name">{eventType}</div>
              <div className="inviteby">
                <i>Invited by</i>
                {invitedBy}
              </div>
              {startDate && <div className="datetime">
                <i>Date & Time</i>
                {formatDate(startDate)}
              </div>}
              {venue && <div className="location">
                <i>Location</i>
                {venue}
              </div>}
            </div>
          )}
        </div>
        <div className="rsvp-invitation-form">
          <h3>Please submit your response.</h3>
          <Formik
            initialValues={{
              name: "",
              mobile: "",
              attendance: "yes",
              remind: true,
              members: 1,
              suggestion: "",
            }}
            validationSchema={RSVPValidationSchema}
            onSubmit={handleSubmit}
          >
            {({ values, handleChange, handleSubmit }) => (
              <Form
                className="rsvp-form"
                onSubmit={handleSubmit}
              >
                {/* Name */}
                <div>
                  <Field
                    type="text"
                    name="name"
                    placeholder="Name"
                    className="rsvp-input"
                  />
                  <ErrorMessage
                    className="rsvp-error"
                    name="name"
                    component="div"
                    style={{ color: "red", fontSize: "12px" }}
                  />
                </div>
                {/* Mobile Number */}
                <div>
                  <Field
                    type="tel"
                    name="mobile"
                    placeholder="Mobile Number"
                    className="rsvp-input"
                  />
                  <ErrorMessage
                    className="rsvp-error"
                    name="mobile"
                    component="div"
                    style={{ color: "red", fontSize: "12px" }}
                  />
                </div>

                {/* Attendance Radio Buttons */}
                <div>
                  <label>Are you coming?</label>
                  <div>
                    <label>
                      <Field type="radio" name="attendance" value="yes" />
                      Yes
                    </label>
                    <label style={{ marginLeft: "10px" }}>
                      <Field type="radio" name="attendance" value="no" />
                      No
                    </label>
                  </div>
                  <ErrorMessage
                    className="rsvp-error"
                    name="attendance"
                    component="div"
                    style={{ color: "red", fontSize: "12px" }}
                  />
                </div>

                {values.attendance === "yes" && (
                  <>
                    {/* Reminder Checkbox */}
                    <div>
                      <label>
                        <Field type="checkbox" name="remind" />
                        Do you want us to remind you for the event?
                      </label>
                    </div>

                    {/* Number of Members */}
                    <div>
                      <Field
                        type="number"
                        name="members"
                        placeholder="Number of Members"
                        min="1"
                        className="rsvp-input"
                      />
                      <ErrorMessage
                        className="rsvp-error"
                        name="members"
                        component="div"
                        style={{ color: "red", fontSize: "12px" }}
                      />
                    </div>
                  </>
                )}
                {/* Suggestion */}
                <div>
                  <Field
                    as="textarea"
                    name="suggestion"
                    placeholder="Any Suggestions for us?"
                    className="rsvp-input"
                  />
                </div>
                {/* Submit Button */}
                <button type="submit" className="rsvp-submit-button">
                  Submit Your Response
                </button>
              </Form>
            )}
          </Formik>
        </div>
      </div>
      <img className="invpat invleft" src={`${process.env.PUBLIC_URL}/assets/images/invleft.png`} />
      <img className="invpat invleftb" src={`${process.env.PUBLIC_URL}/assets/images/invleftb.png`} />
      <img className="invpat invright" src={`${process.env.PUBLIC_URL}/assets/images/invright.png`} />
      <img className="invpat invrightb" src={`${process.env.PUBLIC_URL}/assets/images/invrightb.png`} />
    </div>
  );
};
