// axiosInstance.js
import axios from 'axios';
import config from '../config';
import { getToken } from "../util/Cookies";

// Create an Axios instance
const axiosInstance = axios.create({
  baseURL: config.API_BASE_URL, // Replace with your API URL
  headers: {
    'Content-Type': 'application/json',
  },
});

// Set up an interceptor to inject the token into headers
axiosInstance.interceptors.request.use(
  (config) => {
    console.log('Interceptor is called');
    // const token = localStorage.getItem('authToken'); // Access the token from your auth slice or context
    const token = getToken(); // Access the token from your auth slice or context
    console.log('Token from state:', token);
    if (token) {
    //   config.headers['Authorization'] = `Bearer ${token}`;
      config.headers['Authorization'] = token;
      console.log('Token added to request:', token); // Log the token
    } else {
      console.log('No token found in state'); // Log if no token found
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default axiosInstance;
