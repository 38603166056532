import React from "react";
import MyAppBar from "../component/appBarcomponent/MyAppbar";
//import NavBar from '../component/appBarcomponent/Navbar';
import Body from "./HomeBody";
import useWindowSize from "../util/useWindowSize";
import "../css/Morquee.css";
import {
  CssBaseline,
  Container,
  Stack,
  Box,
  Button,
  TextField,
} from "@mui/material";
import ResponsiveAppBar from "./appBarcomponent/ResponsiveAppbar";
import work from "../assets/images/how-does-it-work.png";
import NewsLetter from "./NewsLetter";
import Footer from "./Footer";
import CardEvents from "./CardEvents";
import common_colors from "../styles/common_colors";
import CreateEventSection from "./CreateEventSection/CreateEventSection";
import Howdoeswork from "./howdoeswork/Howdoeswork";
import Howdoesitwork from "./howdoesitwork/Howdoesitwork";
import MiddleContent from "./MiddleContent";

const HomePage = () => {
  const width = useWindowSize();

  return (
    <div>
      <CssBaseline />
      <Stack spacing={7} sx={{ flexGrow: 1 }}>
        <ResponsiveAppBar />
      </Stack>
      <CreateEventSection />
      <MiddleContent />
      <Howdoesitwork />
      <NewsLetter />
      <Footer />
    </div>
  );
};

export default HomePage;
