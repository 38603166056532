const formatDate = (isoDate) => {
  const date = new Date(isoDate);

  const day = String(date.getDate()).padStart(2, '0'); // Day (2 digits)
  const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  const month = monthNames[date.getMonth()]; // Month name (3-letter abbreviation)
  const year = date.getFullYear().toString().slice(-2); // Last 2 digits of the year
  const time = date.toLocaleString([], {
    hour: "2-digit",
    minute: "2-digit",
});

  return `${day}-${month}-${year} ${time}`; // Return in dd-MMM-yy format
};

export default formatDate;