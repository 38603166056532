import React from "react";
import ReactDOM from "react-dom/client";
import { ThemeProvider } from '@mui/material/styles';
import "./index.css";
import App from "./App";
import theme from './theme';
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import signup_store from "../src/Apiservices/Auth/signup_store";
import login_store from "../src/Apiservices/Auth/store/login_store";
import "primeicons/primeicons.css";
import store from './store/store';

//import reset_store from './Apiservices/Auth/store/reset_store';
//import profile_store from './Apiservices/Auth/store/profile_store';

const root = ReactDOM.createRoot(document.getElementById("root"));
const AppWithStores = () => (
  // <Provider store={login_store}>
  //   <Provider store={signup_store}>
  //  {/* <Provider store={profile_store}> */}
  <ThemeProvider theme={theme}>
    <Provider store={store}>
      <App />
    </Provider>
  </ThemeProvider>
  //  {/* </Provider> */}
  //   </Provider>
  // </Provider>
);
root.render(
  <React.StrictMode>
    <AppWithStores></AppWithStores>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
