// src/components/ProtectedRoute.js
import React from 'react';
import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

const ProtectedRoute = ({ children,requiredRole  }) => {
  const { isLoggedIn, role, providerTypes } = useSelector((state) => state.auth);
  // const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);

  // return isLoggedIn ? children : <Navigate to="/" />;
  if (!isLoggedIn) {
    return <Navigate to="/" />;
  }
  if (requiredRole === "provider" && role === "provider") {
    return children; // Access granted to providers
  }
  if (requiredRole === "admin" && role === "admin") {
    return children; // Access granted to admins
  }

  if (requiredRole === "customer" && role === "customer") {
    return children; // Access granted to customers
  }
  return <Navigate to="/" />;  

};

export default ProtectedRoute;
