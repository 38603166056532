import axiosInstance from "../../../util/axiosInstance";
import config from "../../../config";

export const createEvent = async (evdata) => {

  console.log("Event Creation is called ", evdata);

  var evbody = evdata
    ? {
      eventTitle: evdata.eventTitle,
      eventType: evdata.event?.event,
      eventId: evdata.event?.id,
      description: evdata.eventDesc,
      startDate: evdata.Date,
      venue: evdata.Address,
      endDate: evdata.endDate,
      startTime: evdata.startTime,
      endTime: evdata.endTime,
      locationLat: evdata.locationLat,
      locationLong: evdata.locationLong,
      area: evdata.area,
      city: evdata.city,
      state: evdata.state,
    }
    : {};
  console.log("event body:", evbody);
  try {
    console.log(
      "Current Axios instance headers:",
      axiosInstance.defaults.headers
    );

    const response = await axiosInstance.post("/my-events", evbody);
    const data = await response.data;
    if (response.status === 200 || response.status ===201) {
      return data;
    } else {
      throw Error(data.message || "Failed to create event");
    }
  } catch (ex) {
    console.log("failed to create event:", ex);
  }
};

export const updateEvent = async (evdata, eventid) => {
  // const evdata = JSON.parse(eventPrms);
  console.log("Event Creation is called ", evdata);
  var evbody = evdata
    ? {
      description: evdata.eventDesc,
      startDate: evdata.Date,
      venue: evdata.Address,
      endDate: evdata.endDate,
      locationLat: evdata.locationLat,
      locationLong: evdata.locationLong,
      area: evdata.area,
      city: evdata.city,
      state: evdata.state,
      invitedBy:evdata.invitedBy
    }
    : {};
  console.log("event body:", evbody);
  try {
    console.log(
      "Current Axios instance headers:",
      axiosInstance.defaults.headers
    );

    const response = await axiosInstance.put(`/my-events/${eventid}`, evbody);
    const data = await response.data;
    if (response.status === 200) {
      console.log("event resps:", data.event);
      return true;
    } else {
      throw Error(data.message || "Failed to create event");
    }
  } catch (ex) {
    console.log("failed to create event:", ex);
    return false;
  }
};

// const postEvent = async () => {
//   console.log("create event");
//   const eventBody = localStorage.getItem(config.eventLocalStorageKey);
//   console.log(eventBody);
//   if (eventBody) {
//     await createEvent(eventBody);
//     localStorage.removeItem(config.eventLocalStorageKey);
//   }
// };
