import React, { useCallback, useState, useEffect } from "react";
import { useFormik } from "formik"; // Formik import
import * as Yup from "yup"; // Yup for validation
import {
    Box,
    TextField,
    Button,
    FormControlLabel,
    Switch
} from "@mui/material";
import { Dialog } from "primereact/dialog";
import { Message } from 'primereact/message';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import LockPersonOutlinedIcon from '@mui/icons-material/LockPersonOutlined';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import PhoneAndroidOutlinedIcon from '@mui/icons-material/PhoneAndroidOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import './ServiceProviderDashboard.css';
import { getServiceProviderProfile } from "../../Apiservices/Auth/servers/profileServer";
import { editServiceProviderBasicInfo, changeServiceProviderPassword } from "../../Apiservices/Auth/servers/updateServiceProviderProfile";
import { sendVerificationOTP, verifyWithOTP } from "../../Apiservices/Auth/servers/updateServiceProviderProfile";
import { passwordRegEx } from "../../constant";

const ManageProfileBasicInfo = () => {

    const [providerBasicInfo, setProviderBasicInfo] = useState({});
    const [loading, setLoading] = useState(true);
    const [message, setMessage] = useState("");
    const [isBasicInfoDialogVisible, setBasicInfoIsDialogVisible] = useState(false);
    const [isChangePwdDialogVisible, setChangePwdDialogVisible] = useState(false);
    const [isVerifyDialogVisible, setVerifyDialogVisible] = useState(false);

    const openBasicInfoDialog = () => {
        setBasicInfoIsDialogVisible(true);
    };

    const closeBasicInfoDialog = () => {
        setMessage("");
        setBasicInfoIsDialogVisible(false);
    };

    const openChangePwdDialog = () => {
        setChangePwdDialogVisible(true);
    };

    const closeChangePwdDialog = () => {
        setMessage("");
        setChangePwdDialogVisible(false);
    };

    const openVerifyDialog = () => {
        setVerifyDialogVisible(true);
    };

    const closeVerifyDialog = () => {
        setMessage("");
        fetchProviderData();
        setVerifyDialogVisible(false);
    };

    const fetchProviderData = useCallback(async () => {
        setLoading(true);
        try {
            const providerData = await getServiceProviderProfile();
            setProviderBasicInfo(providerData);
        } catch (error) {
            console.error("Failed to fetch provider:", error);
        } finally {
            setLoading(false);
        }
    }, []);

    useEffect(() => {
        fetchProviderData();
    }, [fetchProviderData]);

    const formikBasicInfo = useFormik({
        enableReinitialize: true,
        initialValues: {
            firstName: providerBasicInfo.firstName ? providerBasicInfo.firstName : "",
            lastName: providerBasicInfo.lastName ? providerBasicInfo.lastName : "",
            //mobileNumber: providerBasicInfo.mobileNumber ? providerBasicInfo.mobileNumber : "",
            isMobileVerified: providerBasicInfo.isMobileVerified ? providerBasicInfo.isMobileVerified : false,
            emailId: providerBasicInfo.emailId ? providerBasicInfo.emailId : "",
            isActive: providerBasicInfo.isActive ? providerBasicInfo.isActive : true
        },
        validationSchema: Yup.object({
            firstName: Yup.string().required("First name is required"),
            lastName: Yup.string().required("Last name is required"),
            email: Yup.string().email("Invalid email address"),
            //mobileNumber: Yup.string().matches(/^[0-9]{10}$/, "Mobile number must be exactly 10 digits").required("Mobile number is required"),
        }),
        onSubmit: async (values) => {
            console.log("EditPdetails", values)
            setMessage("")
            setLoading(true);
            try {
                const response = await editServiceProviderBasicInfo(values);
                console.log(response)
                if (response.success === false) {
                    setMessage(response.message)
                } else {
                    setBasicInfoIsDialogVisible(false);
                }
            } catch (error) {
                console.error("Failed to services:", error);
            } finally {
                setLoading(false);
                fetchProviderData()
            }
        }
    });

    const formikChangePwd = useFormik({
        initialValues: {
            newPassword: "",
        },
        validationSchema: Yup.object().shape({
            newPassword: Yup.string()
                .min(8, "Password must be at least 8 characters long")
                .matches(
                    passwordRegEx,
                    "Password must include at least one uppercase letter, one lowercase letter, one number, and one special character"
                )
                .required("Password is required"),
            confirmPassword: Yup.string()
                .oneOf([Yup.ref('newPassword'), null], 'Passwords do not match')
                .required('Confirm password is required')
        }),
        onSubmit: async (values, { resetForm }) => {
            const result = Object.keys(values)
                .filter(key => key != "confirmPassword")
                .reduce((acc, key) => {
                    acc[key] = values[key];
                    return acc;
                }, {});
            setMessage("")
            setLoading(true);
            try {
                const response = await changeServiceProviderPassword(result);
                if (response) {
                    setMessage(response.message)
                }
            } catch (error) {
                console.error("Failed to services:", error);
            } finally {
                setLoading(false);
                resetForm();
            }
        }
    });

    const formikVerify = useFormik({
        enableReinitialize: true,
        initialValues: {
            identifier: providerBasicInfo?.mobileNumber ? providerBasicInfo?.mobileNumber : "",
            otpCode: "",
            verificationType: "mobile_verification"
        },
        validationSchema: Yup.object().shape({
            otpCode: Yup.string()
                .required("OTP is required"),
        }),
        onSubmit: async (values, { resetForm }) => {
            setMessage("")
            setLoading(true);
            try {
                const response = await verifyWithOTP(JSON.stringify(values));
                if (response) {
                    setMessage(response.message)
                }
            } catch (error) {
                console.error("Failed to services:", error);
            } finally {
                setLoading(false);
                resetForm();
            }
        }
    });

    const handleBasicFieldChange = (field) => (event) => {
        const value = event.target.value;
        formikBasicInfo.setFieldValue(field, value);
        formikChangePwd.setFieldValue(field, value);
        formikVerify.setFieldValue(field, value);
    };

    const textFieldStyles = {
        backgroundColor: "#ffffff",
        width: "100%",
        borderRadius: '4px',
        marginBottom: '15px'
    };

    const verifyMobileNum = async () => {
        const data = await sendVerificationOTP(providerBasicInfo?.mobileNumber);
        const { message, detail } = data;
        console.log("OPT", data)
        setMessage(message)
        setVerifyDialogVisible(true);
    }

    return (
        <Box className="profile-card-panel">
            <Box className="profile-card-panel-header"><label>Basic Info</label><Button
                size='small'
                startIcon={<EditTwoToneIcon />}
                onClick={openBasicInfoDialog}
                sx={{
                    fontWeight: '700',
                    padding: '4px 16px',
                    color: "#ffffff",
                    border: '2px solid #0cc0df',
                    backgroundColor: "#0cc0df",
                    "&:hover": {
                        borderColor: "#1da3ba",
                        backgroundColor: "#1da3ba",
                    }
                }}>
                Edit Basic Info
            </Button></Box>
            {Object.keys(providerBasicInfo).length > 0 ? <Box className="profile-card-panel-body">
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: '20px', alignItems: 'flex-start', justifyContent: 'space-between' }}>
                    <div className="profile-item">
                        <div style={{ width: '100%', display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', gap: '20px' }}><div className="picon bio"><AccountCircleOutlinedIcon /></div>
                            <div style={{ width: 'calc(100% - 70px)', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', gap: '10px' }}>
                                <h2 style={{ margin: "0", fontSize: '18px', lineHeight: '25px', fontWeight: '500' }}>{providerBasicInfo?.firstName} {providerBasicInfo?.lastName}</h2>
                                <div>ID: {providerBasicInfo?.id} </div>
                                <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between', gap: '30px' }}><Button
                                    onClick={openChangePwdDialog}
                                    size="small"
                                    variant="outlined"
                                    startIcon={<LockPersonOutlinedIcon />}
                                    sx={{
                                        color: '#0cc0df',
                                        borderColor: '#0cc0df'
                                    }}
                                >
                                    Change Password
                                </Button>
                                    <Dialog header="Change Password" visible={isChangePwdDialogVisible} onHide={closeChangePwdDialog}>
                                        {message && <Message severity="success" text={message} style={{ width: '100%', marginBottom: '30px' }} />}
                                        <div className="p-fluid">
                                            <div className='serviceForm'>
                                                {/* Form Fields */}
                                                <form id="ChangePwdForm" onSubmit={formikChangePwd.handleSubmit}>
                                                    <TextField
                                                        type="password"
                                                        name="newPassword"
                                                        label="New Password"
                                                        value={formikChangePwd.values.newPassword}
                                                        onChange={handleBasicFieldChange("newPassword")} // Use common change handler
                                                        size="small"
                                                        variant="outlined"
                                                        sx={textFieldStyles}
                                                        error={formikChangePwd.touched.newPassword && Boolean(formikChangePwd.errors.newPassword)}
                                                        helperText={formikChangePwd.touched.newPassword && formikChangePwd.errors.newPassword}
                                                    />
                                                    <TextField
                                                        type="password"
                                                        name="confirmPassword"
                                                        label="Confirm password"
                                                        value={formikChangePwd.values.confirmPassword}
                                                        onChange={handleBasicFieldChange("confirmPassword")} // Use common change handler
                                                        size="small"
                                                        variant="outlined"
                                                        sx={textFieldStyles}
                                                        error={formikChangePwd.touched.confirmPassword && Boolean(formikChangePwd.errors.confirmPassword)}
                                                        helperText={formikChangePwd.touched.confirmPassword && formikChangePwd.errors.confirmPassword}
                                                    />
                                                    <Button
                                                        type="submit"
                                                        sx={{
                                                            width: '100%',
                                                            height: '40px',
                                                            color: "#ffffff",
                                                            backgroundColor: "#0cc0df",
                                                            "&:hover": {
                                                                backgroundColor: "#1da3ba",
                                                            },
                                                        }}
                                                    >
                                                        Submit
                                                    </Button>
                                                </form>
                                                {/* Form Fields */}
                                            </div>
                                        </div>
                                    </Dialog>
                                    {/* <FormControlLabel
                                        sx={{
                                            justifyContent: 'space-between',
                                            margin: 0,
                                            "& .MuiFormControlLabel-label": {
                                                fontFamily: 'inherit',
                                                fontSize: '14px',
                                                fontWeight: '500',
                                                color: 'inherit'
                                            }
                                        }}
                                        control={
                                            <Switch
                                                name="isActive"
                                                value={providerBasicInfo.isActive}
                                                onChange={handleBasicFieldChange("isActive")}
                                                inputProps={{ 'aria-label': 'controlled' }}
                                            />
                                        }
                                        label="Deactivate your account"
                                        labelPlacement="start"
                                    /> */}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="profile-item">
                        <div style={{ width: '100%', display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', gap: '20px' }}><div className="picon con"><PhoneAndroidOutlinedIcon /></div>
                            <div style={{ width: 'calc(100% - 70px)', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', gap: '10px' }}>
                                <h2 style={{ margin: "0", fontSize: '18px', lineHeight: '25px', fontWeight: '500' }}>{providerBasicInfo?.mobileNumber}</h2>

                                <div>{providerBasicInfo?.isMobileVerified ?
                                    <div className="verify success">
                                        <CheckCircleRoundedIcon style={{ fontSize: 'inherit' }} />
                                        <span>Verified</span>
                                    </div> :
                                    <div className="verify error">
                                        <span>Mobile number not verified.</span>
                                        <span className="check" onClick={verifyMobileNum}>verify</span>
                                    </div>}
                                </div>
                                <Dialog header="Verify OTP" visible={isVerifyDialogVisible} onHide={closeVerifyDialog}>
                                    {message && <Message severity="success" text={message} style={{ width: '100%', marginBottom: '30px' }} />}
                                    <div className="p-fluid">
                                        <div className='serviceForm'>
                                            {/* Form Fields */}
                                            <form id="VerifyForm" onSubmit={formikVerify.handleSubmit}>
                                                <TextField
                                                    type="hidden"
                                                    name="identifier"
                                                    value={formikVerify.values.identifier}
                                                />
                                                <TextField
                                                    name="otpCode"
                                                    label="Enter OTP"
                                                    value={formikVerify.values.otpCode}
                                                    onChange={handleBasicFieldChange("otpCode")} // Use common change handler
                                                    size="small"
                                                    variant="outlined"
                                                    sx={textFieldStyles}
                                                    error={formikVerify.touched.otpCode && Boolean(formikVerify.errors.otpCode)}
                                                    helperText={formikVerify.touched.otpCode && formikVerify.errors.otpCode}
                                                />
                                                <Button
                                                    type="submit"
                                                    sx={{
                                                        width: '100%',
                                                        height: '40px',
                                                        color: "#ffffff",
                                                        backgroundColor: "#0cc0df",
                                                        "&:hover": {
                                                            backgroundColor: "#1da3ba",
                                                        },
                                                    }}
                                                >
                                                    Submit
                                                </Button>
                                            </form>
                                            {/* Form Fields */}
                                        </div>
                                    </div>
                                </Dialog>
                            </div>
                        </div>
                    </div>
                    <div className="profile-item">
                        <div style={{ width: '100%', display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', gap: '20px' }}><div className="picon eml"><EmailOutlinedIcon /></div>
                            <div style={{ width: 'calc(100% - 70px)', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', gap: '10px' }}>
                                <h2 style={{ margin: "0", fontSize: '18px', lineHeight: '25px', fontWeight: '500' }}>{providerBasicInfo?.emailId}</h2>

                                {/* <div>{providerBasicInfo?.isMobileVerified ?
                                    <div className="verify success">
                                        <CheckCircleRoundedIcon style={{ fontSize: 'inherit' }} />
                                        <span>Verified</span>
                                    </div> :
                                    <div className="verify error">
                                        <span>Mobile number not verified.</span>
                                        <span className="check" onClick={verifyMobileNum}>verify</span>
                                    </div>}
                                </div> */}                                
                            </div>
                        </div>
                    </div>
                    <Dialog header="Edit Basic Info" visible={isBasicInfoDialogVisible} onHide={closeBasicInfoDialog}>
                        {message && <Message severity="error" text={message} style={{ width: '100%', marginBottom: '30px' }} />}
                        <div className="p-fluid">
                            <div className='serviceForm'>
                                {/* Form Fields */}
                                <form id="BasicInfoForm" onSubmit={formikBasicInfo.handleSubmit}>
                                    <TextField
                                        name="firstName"
                                        label="First Name"
                                        value={formikBasicInfo.values.firstName}
                                        onChange={handleBasicFieldChange("firstName")} // Use common change handler
                                        size="small"
                                        variant="outlined"
                                        sx={textFieldStyles}
                                        error={formikBasicInfo.touched.firstName && Boolean(formikBasicInfo.errors.firstName)}
                                        helperText={formikBasicInfo.touched.firstName && formikBasicInfo.errors.firstName}
                                    />
                                    <TextField
                                        name="lastName"
                                        label="Last Name"
                                        value={formikBasicInfo.values.lastName}
                                        onChange={handleBasicFieldChange("lastName")} // Use common change handler
                                        size="small"
                                        variant="outlined"
                                        sx={textFieldStyles}
                                        error={formikBasicInfo.touched.lastName && Boolean(formikBasicInfo.errors.lastName)}
                                        helperText={formikBasicInfo.touched.lastName && formikBasicInfo.errors.lastName}
                                    />
                                    {/* <TextField
                                        name="mobileNumber"
                                        label="Mobile Number"
                                        value={formikBasicInfo.values.mobileNumber}
                                        onChange={handleBasicFieldChange("mobileNumber")} // Use common change handler
                                        size="small"
                                        variant="outlined"
                                        sx={textFieldStyles}
                                        error={formikBasicInfo.touched.mobileNumber && Boolean(formikBasicInfo.errors.mobileNumber)}
                                        helperText={formikBasicInfo.touched.mobileNumber && formikBasicInfo.errors.mobileNumber}
                                    /> */}
                                    <TextField
                                        name="emailId"
                                        label="Email"
                                        value={formikBasicInfo.values.emailId}
                                        onChange={handleBasicFieldChange("emailId")} // Use common change handler
                                        size="small"
                                        variant="outlined"
                                        sx={textFieldStyles}
                                        error={formikBasicInfo.touched.emailId && Boolean(formikBasicInfo.errors.emailId)}
                                        helperText={formikBasicInfo.touched.emailId && formikBasicInfo.errors.emailId}
                                    />
                                    <Button
                                        type="submit"
                                        sx={{
                                            width: '100%',
                                            height: '40px',
                                            color: "#ffffff",
                                            backgroundColor: "#0cc0df",
                                            "&:hover": {
                                                backgroundColor: "#1da3ba",
                                            },
                                        }}
                                    >
                                        Update Basic Info
                                    </Button>
                                </form>
                                {/* Form Fields */}
                            </div>
                        </div>
                    </Dialog>
                </Box>
            </Box> : <Box className="profile-card-panel-body"><h4>Loading</h4></Box>}
            {loading && <div className='loading'></div>}
        </Box>
    )
}
export default ManageProfileBasicInfo;