import { redirect } from 'react-router-dom';
import config from '../../../config'
import axiosInstance from '../../../util/axiosInstance';
import { getToken } from "../../../util/Cookies";
import { decodeToken } from "react-jwt";

export const updateServiceProviderServices = async (services) => {
  const token = getToken();
  try {
    const response = await axiosInstance.put('/services', services, {
      method: "PUT",
      headers: {
        Authorization: `${token}`,
      }
    })

    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error(response.message || 'Unable to get the provider details');
    }
  } catch (error) {
    // dispatch(profileFailuer(error.message));
    console.error('There was a problem with the fetch operation:', error);
  }
};

export const addServiceProviderServices = async (services) => {
  const token = getToken();
  try {
    const response = await axiosInstance.post('/services/bulk-create', services, {
      method: "POST",
      headers: {
        Authorization: `${token}`,
      }
    })

    if (response.status === 201) {
      return response.data;
    } else {
      throw new Error(response.message || 'Unable to get the provider details');
    }
  } catch (error) {
    // dispatch(profileFailuer(error.message));
    console.error('There was a problem with the fetch operation:', error);
  }
};

export const editServiceProviderServices = async (services) => {
  const token = getToken();
  try {
    const response = await axiosInstance.put('/services/bulk-update', services, {
      method: "PUT",
      headers: {
        Authorization: `${token}`,
      }
    })

    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error(response.message || 'Unable to get the provider details');
    }
  } catch (error) {
    // dispatch(profileFailuer(error.message));
    console.error('There was a problem with the fetch operation:', error);
  }
};