import { signupRequest, signupSuccess, signupFailure } from "../signUpAction";
import config from "../../../config";
import axiosInstance from "../../../util/axiosInstance";

export const signup = async (userData) => {
  // dispatch(signupRequest());
  try {
    // console.log('userdata:',JSON.stringify(userData));
    const response = await axiosInstance.post(`/auth/signup`, userData);
    const data = await response.data;
    if (response.status === 200 || response.status === 201) {
      console.log("success:", data);
      const {message ,token} = data;
      return data;
      // dispatch(signupSuccess(data));
    }
  } catch (error) {
    // Check if error response exists and log the error message
    if (error.response) {
      throw new Error(error.response.data.message); // Throw error message from server
    } else {
      throw new Error("Something Went Wrong"); // General error message
    }
    // dispatch(signupFailure(error.message));
  }
};
