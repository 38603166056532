import React, { useEffect, useState } from "react";
import {
  CssBaseline,
  Stack,
} from "@mui/material";
import "../css/Morquee.css";
import MiddleContent from "./MiddleContent";
import Footer from "./Footer";
//import { getProfile } from "../Apiservices/Auth/servers/profileServer";
import ResponsiveAppBar from "./appBarcomponent/ResponsiveAppbar";
import EventTabs from "./eventComponent/EventTabs/EventTabs";

const Dashboard = () => {
  /* const [userDetails, setUsers] = useState({});
  useEffect(() => {
    const fetchuser = async () => {
      const user_data = await getProfile();
      setUsers(user_data);
    };
    fetchuser();
  }, []); */
  return (
    <div style={{ width: "100%" }}>
      <CssBaseline />
      <Stack spacing={7} sx={{ flexGrow: 1 }}>
        <ResponsiveAppBar params={"dashboard"} />
      </Stack>
      <EventTabs />
      <MiddleContent params={"dashboard"}/>
      <Footer />
    </div>
  );
};

export default Dashboard;
