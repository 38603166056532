import axiosInstance from "../../../util/axiosInstance"; // Make sure to import your axios instance

export const fetchServices = async (providerTypeID) => {
  try {
    const url = `/master/services?isSegregated=true&providerTypeID=${providerTypeID}`;
    const response = await axiosInstance.get(url);
    const services = response.data || [];
    console.log("services:", services);
    return services;
  } catch (ex) {
    console.log("failed to load service:", ex.message);
    return [];
  }
};
