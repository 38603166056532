import React from 'react';
import IconButton from '@mui/material/IconButton';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import InstagramIcon from '@mui/icons-material/Instagram';

const SocialMediaIcons = () => {
  return (
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
      <IconButton
        aria-label="Facebook"
        href="https://www.facebook.com"
        target="_blank"
        style={{ color: '#4267B2', padding: '8px' }} // Facebook's brand color
      >
        <FacebookIcon />
      </IconButton>
      <IconButton
        aria-label="Twitter"
        href="https://www.twitter.com"
        target="_blank"
        style={{ color: '#1DA1F2', padding: '8px' }} // Twitter's brand color
      >
        <TwitterIcon />
      </IconButton>
      <IconButton
        aria-label="LinkedIn"
        href="https://www.linkedin.com"
        target="_blank"
        style={{ color: '#0077B5', padding: '8px' }} // LinkedIn's brand color
      >
        <LinkedInIcon />
      </IconButton>
      <IconButton
        aria-label="Instagram"
        href="https://www.instagram.com"
        target="_blank"
        style={{ color: '#E4405F', padding: '8px' }} // Instagram's brand color
      >
        <InstagramIcon />
      </IconButton>
    </div>
  );
}

export default SocialMediaIcons;
