import { Button } from "primereact/button";
import React from "react";
import {  useNavigate } from "react-router-dom";

const BackToHome = () => {
  const navigate = useNavigate();
  return (
    <div
      className="back-to-home-container"
      style={{ textAlign: "center", marginTop: "15px" }}
    >
      <Button
        label="Back to Home"
        icon="pi pi-home"
        raised
        className="p-button-outlined p-button-secondary"
        style={{
          fontSize: "14px",
          padding: "8px 12px",
          margin: "10px 0", 
          backgroundColor: "#FF895D", 
          color: "#fff", 
        }}
        iconPos="right"
        onClick={() => navigate("/")}
      />
    </div>
  );
};

export default BackToHome;
