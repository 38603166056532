import React, { useCallback, useEffect, useState } from "react";
import {
    CssBaseline,
    Stack,
    TextField,
    MenuItem
} from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useLocation, useNavigate } from "react-router-dom";
import Footer from "../Footer";
import ResponsiveAppBar from "../appBarcomponent/ResponsiveAppbar";
import "../eventComponent/EventTabs/EventTabs.css"
import { Button } from "primereact/button";
import { fetchchatGptData, fetchchatGptHowTo } from "../../Apiservices/Auth/servers/chatGptServer";
import { fetchProviderData } from "../../Apiservices/Auth/servers/fetchProviderData";
import ReligionAndCaste from "../../ReligionAndCaste.json"

function EventChatGPT() {
    const [loading, setLoading] = useState(true);
    const [castes, setCastes] = useState([]);
    const location = useLocation();
    const [providerData, setProviderData] = useState([]);
    const eventDetails = location.state || {}; // Get event details

    const { id, eventTitle } = eventDetails;

    const [howTo, setHowTO] = useState("")
    const [context, setcontext] = useState("")

    const navigate = useNavigate();

    const backtoEvents = () => {
        navigate("/dashboard");
    }

    const fetchProviders = useCallback(async () => {
        setLoading(true);
        try {
            const provider_data = await fetchProviderData();
            setProviderData(provider_data);
        } catch (error) {
            console.error("Failed to fetch events:", error);
        } finally {
            setLoading(false);
        }
    }, []);

    useEffect(() => {
        fetchProviders();
    }, []);

    const handleEventChatGPT = () => {
        if (eventDetails && Object.keys(eventDetails).length > 0)
            navigate("/dashboard/event/know-what-how", { state: eventDetails });
    };

    const handleEventService = (service) => {
        if (eventDetails && Object.keys(eventDetails).length > 0) {
            const info = {
                serviceDetails: service,
                eventDetails: eventDetails
            }
            navigate("/dashboard/event/service", { state: info });
            window.scrollTo({ top: 0, behavior: 'smooth' });
        }
    }

    function removeBlankAttributes(obj) {
        const result = {};
        for (const key in obj) {
            if (obj[key] !== '' && obj[key] !== undefined) {
                result[key] = obj[key];
            }
        }
        return result;
    }

    useEffect(() => {
        const fetchHowTo = async () => {
            setLoading(true);
            //const question = { question: `Please tell me about the event ${eventTitle}` }
            //const apiResponse = await fetchchatGptHowTo(question);
            const apiResponse = await fetchchatGptData({ event: eventTitle });
            setcontext(`Just as Grandma used to say, here are the essential steps followed for ${eventTitle}`)
            setHowTO(apiResponse["answer"]);
            setLoading(false);
        }
        fetchHowTo()
    }, []);

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            Religion: "",
            Caste: "",
            Language: "",
            Location: "",
            event: eventTitle,
        },
        onSubmit: async (values, { resetForm }) => {
            const altered = removeBlankAttributes(values);
            try {
                setLoading(true);
                const apiResponse = await fetchchatGptData(altered);
                setcontext(`Just as Grandma used to say, here are the essential steps followed for ${eventTitle}`)
                setHowTO(apiResponse["answer"]); // Set the mock message
            } catch (error) {
                console.error("Failed to services:", error);
            } finally {
                setLoading(false);
                resetForm();
            }
        },
    });

    const textFieldStyles = {
        backgroundColor: "#ffffff",
        width: "100%",
        borderRadius: "4px",
        marginBottom: "0",
    };

    const setCasteByReligion = (value) => {
        const result = ReligionAndCaste.religion.find(obj => obj.name === value)
        setCastes(result.castes)
    }

    const handleReligionChange = (field) => (event) => {
        const value = event.target.value;
        formik.setFieldValue(field, value);
        setCasteByReligion(value)
    };

    const handleFieldChange = (field) => (event) => {
        const value = event.target.value;
        formik.setFieldValue(field, value);
    };

    const languages = ["Bengali", "Gujarati", "Hindi", "Kannada", "Malayalam", "Manipuri", "Marathi", "Odia", "Tamil", "Telugu", "Urdu", "Assamese", "Bhojpuri", "Nepali", "Punjabi", "Sanskrit", "Sindhi"];

    const createEventHandle = () => {
        // setCreateEvent(false)
        // setIsFirstVisit(false)
        navigate("/dashboard/event/create");
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    return (
        <div style={{ width: "100%" }}>
            <CssBaseline />
            <Stack spacing={7} sx={{ flexGrow: 1 }}>
                <ResponsiveAppBar params={"dashboard"} />
            </Stack>
            <div className="main-container">
                <div className="frontend-container">
                    <div style={{ width: '100%', paddingBottom: '30px' }}>
                        <div className="event-form-inner">
                            <div className="event-form-head">
                                <div className="event-service-head-inner">
                                    <img
                                        style={{
                                            display: "block",
                                            width: "50px",
                                        }}
                                        src={`${process.env.PUBLIC_URL}/assets/images/1.png`}
                                        alt={`Know What & How About ${eventTitle}`}
                                    />
                                    <div>
                                        <h3>Know What & How About {eventTitle}</h3>
                                        <div className="event-rsvp">
                                            Our AI Powered Thurent, gives you details of the event.
                                        </div>
                                    </div>
                                </div>
                                <div className="btns group">
                                    <Button
                                        icon="pi pi-angle-left"
                                        className="custom-button"
                                        label="Go Back"
                                        onClick={backtoEvents}
                                    />
                                    <Button
                                        icon="pi pi-plus"
                                        className="custom-button"
                                        label="Create Event"
                                        onClick={createEventHandle}
                                    />
                                </div>
                            </div>
                            <div className="event-form-body list">
                                <div className="chatgpt-cont">
                                    {loading ? <i className="pi pi-spin pi-spinner-dotted" style={{ fontSize: '2rem', color: '#0cc0df' }}></i> : <><h3>{context}</h3><pre style={{ whiteSpace: 'break-spaces' }}>{howTo ? howTo : ""}</pre></> }
                                </div>
                                <div className="event-service-list">
                                    <div className="event-service alter">
                                        <form onSubmit={formik.handleSubmit} style={{ width: '100%', border: 0, marginBottom: '10px' }}>
                                            <div className="event-service-head-inner">
                                                <div className="event-chantgpt-fields">
                                                    <TextField
                                                        type="hidden"
                                                        name="event"
                                                        value={formik.values.event}
                                                        style={{ position: 'absolute', zIndex: -1 }}
                                                    />
                                                    <TextField
                                                        size="small"
                                                        variant="outlined"
                                                        name="Religion"
                                                        label="Select Religion"
                                                        select
                                                        value={formik.values.Religion}
                                                        onChange={handleReligionChange('Religion')}
                                                        sx={textFieldStyles}
                                                    >
                                                        {ReligionAndCaste.religion.map((option) => (
                                                            <MenuItem key={option.id} value={option.name}>
                                                                {option.name}
                                                            </MenuItem>
                                                        ))}
                                                    </TextField>
                                                    <TextField
                                                        size="small"
                                                        variant="outlined"
                                                        name="Caste"
                                                        label="Select Caste"
                                                        select
                                                        value={formik.values.Caste}
                                                        onChange={handleFieldChange('Caste')}
                                                        sx={textFieldStyles}
                                                    >
                                                        {castes.length > 0 && castes.map((option) => (
                                                            <MenuItem key={option.id} value={option.name}>
                                                                {option.name}
                                                            </MenuItem>
                                                        ))}
                                                    </TextField>
                                                    <TextField
                                                        size="small"
                                                        variant="outlined"
                                                        name="Language"
                                                        label="Select Language"
                                                        select
                                                        value={formik.values.Language}
                                                        onChange={handleFieldChange('Language')}
                                                        sx={textFieldStyles}
                                                    >
                                                        {languages.length > 0 && languages.map((option) => (
                                                            <MenuItem key={option} value={option}>
                                                                {option}
                                                            </MenuItem>
                                                        ))}
                                                    </TextField>
                                                    <TextField
                                                        size="small"
                                                        name="Location"
                                                        label="Enter Region / State"
                                                        value={formik.values.Location}
                                                        onChange={handleFieldChange("Location")} // Use common change handler
                                                        variant="outlined"
                                                        sx={textFieldStyles}
                                                    />
                                                    <Button
                                                    style={{width:'100%'}}
                                                    type="submit"
                                                    className="custom-button"
                                                    label="Steps"
                                                />
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                    {providerData.length > 0 && providerData.map((item) => <div key={item.id} className="event-service" onClick={() => handleEventService(item)}>
                                        <div className="event-service-head">
                                            <div>
                                                <div>
                                                    <h3>{item.serviceName}</h3>
                                                    <div className="event-rsvp">
                                                        {item.serviceDescription}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="pi pi-chevron-right"></div>
                                        </div>
                                    </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div >
    )
}

export default EventChatGPT