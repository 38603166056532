import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik"; // Formik import
import * as Yup from "yup"; // Yup for validation
import { serviceprovidersignup } from "../../Apiservices/Auth/servers/serviceProviderSignUpServer";
import useWindowSize from "../../util/useWindowSize";
import common_colors from "../../styles/common_colors";
import "./ServiceProviderSignup.css";
import { Message } from 'primereact/message';
import { Dialog } from 'primereact/dialog';
import { ButtonGroup } from 'primereact/buttongroup';
import { Button } from 'primereact/button';
import { setToken } from "../../util/Cookies";
import { handlePendingEvent, setCredentials } from "../../store/authSlice";
import { fetchProviderData } from "../../Apiservices/Auth/servers/fetchProviderData";
import { passwordRegEx } from "../../constant";
import BackToHome from "../BacktoHomeComponent/BackToHome";

const ServiceProviderSignup = () => {
  const phoneRegExp = /^[6-9]\d{9}$/;
  const [dataerror, setDataError] = useState(null);
  const [providerData, setProviderData] = useState([]);

  const [showErrorDialogVisible, setShowErrorDialogVisible] = useState(false);
  const [errorDialogCont, setErrorDialogCont] = useState({});

  const dispatch = useDispatch();
  const authState = useSelector((state) => state);
  const { loading, user, error } = authState || {};
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [provider_data] = await Promise.all([fetchProviderData()]);
        setProviderData(provider_data);
      } catch (error) {
        setDataError("Failed to fetch data.");
      }
    };
    fetchData();
  }, []);

  const showErrorDialog = (msg) => {
    setShowErrorDialogVisible(true);
    switch (msg) {
      case 'User already exists.':
        setErrorDialogCont({ content: 'A user with this email or mobile number already exists.', btn: true })
        break;
      default:
        setErrorDialogCont({ content: msg, btn: false })
    }
  }

  // Formik form handling
  const formik = useFormik({
    initialValues: {
      providerTypeIds: [],
      firstName: "",
      lastName: "",
      email: "",
      mobileNumber: "",
      password: "",
    },
    validationSchema: Yup.object({
      providerTypeIds: Yup.array()
        .min(1, "At least one provider type must be selected")
        .required("Required"),
      firstName: Yup.string().required("First Name is required"),
      lastName: Yup.string().required("Last Name is required"),
      email: Yup.string().email("Invalid email address"),
      mobileNumber: Yup.string()
        .matches(/^[0-9]{10}$/, "Mobile number must be exactly 10 digits")
        .required("Mobile number is required")
        .test(
          "is-valid-phone-or-email",
          "Invalid mobile number",
          function (value) {
            if (!value) return false; // Empty check is already handled by .required()
            const isValidPhone = phoneRegExp.test(value);
            console.log(isValidPhone);
            return isValidPhone;
          }
        ),
      password: Yup.string()
        .min(8, "Password must be at least 8 characters long")
        .matches(
          passwordRegEx,
          "Password must include at least one uppercase letter, one lowercase letter, one number, and one special character"
        )
        .required("Password is required"),
    }),
    onSubmit: async (values) => {
      try {
        console.log("Signup", values);
        const data = await serviceprovidersignup(values);
        const { message, token } = data;
        if (data.token) {
          // Signup Successful
          console.log("Signup Successful");
          setToken(data.token);
          dispatch(setCredentials(data));
          dispatch(handlePendingEvent());
          navigate("/service-provider/dashboard");
        }
      } catch (error) {
        showErrorDialog(error.message || "")
        console.log("Error: In Signup ");
        console.log(error);
      }
    },
  });
  return (
    <div className="auth-screens-bg">
      <Dialog className="auth-dialog" visible={showErrorDialogVisible} modal header={`Error`} footer={errorDialogCont.btn ? <div><Button size="small" label="Login" onClick={() => navigate("/service-provider/login")} /><Button size="small" label="Forgot Password" onClick={() => navigate("/service-provider/forgot-password")} /></div> : <Button size="small" label="OK" onClick={() => setShowErrorDialogVisible(false)} />} onHide={() => { if (!showErrorDialogVisible) return; setShowErrorDialogVisible(false); }}>
        <h4 style={{ fontWeight: '500' }}>
          {errorDialogCont.content}
        </h4>
      </Dialog>
      <div className="signup-card">
        {/* <Link to="/" className="auth-logo-link"> */}
        <img
          src={`${process.env.PUBLIC_URL}/assets/images/thurent.png`}
          alt="Logo"
          className="auth-logo"
          onClick={() => {
            navigate("/");
          }}
        />
        {/* </Link> */}

        {/* <h2 style={{ textAlign: 'center' }}>Sign Up</h2> */}
        <form onSubmit={formik.handleSubmit}>
          <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'space-between', gap: '5px' }}>
            <div>
              <input
                type="text"
                name="firstName"
                placeholder="First Name"
                value={formik.values.firstName}
                onChange={formik.handleChange}
                className="signup-input"
              />
              {formik.touched.firstName && formik.errors.firstName && (
                <span className="error">{formik.errors.firstName}</span>
              )}
            </div>
            <div>
              <input
                type="text"
                name="lastName"
                placeholder="Last Name"
                value={formik.values.lastName}
                onChange={formik.handleChange}
                className="signup-input"
              />
              {formik.touched.lastName && formik.errors.lastName && (
                <span className="error">{formik.errors.lastName}</span>
              )}
            </div>

          </div>

          <input
            type="email"
            name="email"
            placeholder="Email"
            value={formik.values.email}
            onChange={formik.handleChange}
            className="signup-input"
          />
          {formik.touched.email && formik.errors.email && (
            <span className="error">{formik.errors.email}</span>
          )}

          <input
            type="text"
            name="mobileNumber"
            maxLength="10"
            placeholder="Mobile Number"
            value={formik.values.mobileNumber}
            onChange={formik.handleChange}
            className="signup-input"
          />
          {formik.touched.mobileNumber && formik.errors.mobileNumber && (
            <span className="error">{formik.errors.mobileNumber}</span>
          )}

          <input
            type="password"
            name="password"
            placeholder="Password"
            value={formik.values.password}
            onChange={formik.handleChange}
            className="signup-input"
          />
          {formik.touched.password && formik.errors.password && (
            <span className="error">{formik.errors.password}</span>
          )}

          <fieldset className="signup-input checkbox">
            <legend>Select Provider Type(s)</legend>
            {providerData.length > 0 &&
              providerData.map((item) => {
                return (
                  <label key={item?.id}>
                    <input
                      name="providerTypeIds"
                      type="checkbox"
                      onChange={formik.handleChange}
                      value={item?.id}
                    />
                    {item?.type}
                  </label>
                );
              })}
          </fieldset>
          {formik.touched.providerTypeIds && formik.errors.providerTypeIds && (
            <span className="error">{formik.errors.providerTypeIds}</span>
          )}
          <button type="submit" disabled={loading} className="signup-button">
            {loading ? "Signing Up..." : "Signup"}
          </button>
          {error && <span className="error">Error: {error}</span>}
        </form>

        {user && (
          <div className="success">
            <p>
              Welcome, {user.firstName}!{" "}
              <Link to="/service-provider/login">Login</Link>
            </p>
          </div>
        )}

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            fontSize: "0.85rem",
            fontWeight: "500",
          }}
        >
          Already have an account?{" "}
          <Link
            to="/service-provider/login"
            style={{ color: "#FF895D", margin: "0 4px" }}
          >
            Login
          </Link>
        </div>

        <div
          style={{
            textAlign: "left",
            fontSize: "0.75rem",
            fontWeight: "500",
            marginTop: "0.75rem",
          }}
        >
          <Message className="pwd-info" severity="info" text="Password must include AT LEAST ONE uppercase, lowercase, number and special character." />
        </div>
        <BackToHome />
      </div>
    </div>
  );
};

export default ServiceProviderSignup;
