const config = {
  companyName: "Thurent.com",
  eventLocalStorageKey: "createEventFormSession",
  eventEditLocalStorageKey: "editEventFormSession",
  
  API_BASE_URL:
    "https://backend-dev.thurent.com/api",
  // API_BASE_URL: 'http://localhost:3001/api',
  INVITATION_URL: "https://thurent.com/invitation",
  // Add other configurations if needed
  token:
    "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOjEsImlhdCI6MTcyNTU2NTc2MCwiZXhwIjoxNzI1NTY5MzYwfQ.yHdfylPCyDVYm_8aSEpoC_Yc5YOF-z4__diuPB_C1mQ",
};

export default config;
