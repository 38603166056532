import React from 'react';
import './RollingPaper.css'; // Updated CSS

const RollingPaperText = ({ myText }) => {
    // const myText="Namakarana, also known as the Naming Ceremony, is an important ritual in Hinduism that is performed to give a newborn baby its name. In the Telugu-speaking Brahmin community, this ceremony holds great significance and is usually conducted on the 11th or 21st day after the birth of the child.\n\nThe process of the Namakarana ceremony typically involves the following steps:\n\n1. The family priest or a learned elder performs a puja (ritual worship) to invoke the blessings of the deities and ancestors.\n\n2. The baby is bathed and dressed in new clothes.\n\n3. The priest recites mantras and prayers, seeking the blessings of the gods for the well-being and prosperity of the child.\n\n4. The baby's horoscope is consulted to determine an auspicious time and date for the naming ceremony.\n\n5. The parents choose a suitable name for the child, often based on the baby's horoscope or the family's traditions.\n\n6. The priest then whispers the chosen name into the baby's ear, symbolically bestowing it upon the child.\n\n7. The family and guests present at the ceremony offer their blessings and gifts to the newborn.\n\nIn the Telugu Brahmin community, the Namakarana ceremony is usually followed by a feast or a small gathering to celebrate the occasion.\n\nIn terms of caste and subcaste, being a Brahmin in the Telugu-speaking community may influence certain customs and traditions observed during the Namakarana ceremony. The gotram (ancestral lineage) of the family may also play a role in determining certain aspects of the ceremony.\n\nOther parameters such as the specific rituals, mantras, and customs followed during the Namakarana ceremony may vary based on regional and family traditions within the Telugu Brahmin community. It is important to consult with a knowledgeable priest or elder within the community to ensure that the ceremony is conducted in accordance with the family's beliefs and practices.";
  // Convert newlines to <br> and handle multiple newlines
  const formattedText = myText
    .split('\n\n')
    .map((paragraph, index) => (
      <React.Fragment key={index}>
        {paragraph.split('\n').map((line, lineIndex) => (
          <React.Fragment key={lineIndex}>
            {line}
            <br />
          </React.Fragment>
        ))}
        <br />
      </React.Fragment>
    ));

  return <div className="rolling-paper">{formattedText}</div>;
};

export default RollingPaperText;
