import axiosInstance from "../util/axiosInstance";

export const fetchRSVPDetails = async (sharedToken) => {
  try {
    const response = await axiosInstance.get(
      `/my-events/shared/${sharedToken}`
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching RSVP details:", error);
    throw error;
  }
};

export const submitRSVPResponse = async (responseData) => {
  try {
    const {
      name,
      phoneNumber,
      isComing,
      remind,
      extraMembers,
      remarks,
      sharedToken,
    } = responseData;

    // Transforming the data as per your requirements
    const dataToSubmit = {
      name,
      phoneNumber,
      sharedToken,
      isComing: isComing,
      isReminderRequired: remind,
      extraMembers: extraMembers, // Assuming this is the count of extra members
      remarks: remarks,
    };

    const response = await axiosInstance.post(
      `/rsvp/guests/responses`,
      dataToSubmit
    );
    return response; // This will return the response from the server
  } catch (error) {
    if (error.response) {
      if (error.response.status === 409) {
        // Return a 409 error response instead of throwing to be caught in handleSubmit
        return error.response;
      }
    }
    throw new Error("An error occurred while submitting the response.");
  }
};

export const getGuestListWithResponse = async ({ shareToken }) => {
  try {
    const response = await axiosInstance.get(
      `/rsvp/guests-list/all/${shareToken}`
    );
    if (response.status === 200) {
      return response.data.guests; // This will return the response from the server
    } else {
      return [];
    }
  } catch (error) {
    if (error.response) {
      if (error.response.status === 409) {
        // Return a 409 error response instead of throwing to be caught in handleSubmit
        return error.response;
      }
    }
    throw new Error("An error occurred while getting Guest List response.");
  }
};
